import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmBoxService } from '@theme/layouts';
import { NavbarService } from '@theme/layouts/navbars/services/navbar.service';

@Component({
  selector: 'ds365-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  collapsed = false;
  @Input() sideBarData: any;
  isExternalUser = false;

  @Output()
  event = new EventEmitter();

  constructor(
    breakpointObserver: BreakpointObserver,
    private router: Router,
    private route: ActivatedRoute,
    private confirmService: ConfirmBoxService,
    private navbarService: NavbarService
  ) {
    breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletLandscape]).subscribe(result => {
      if (result.matches) {
        this.collapsed = true;
      }
    });
    breakpointObserver.observe([Breakpoints.Medium, Breakpoints.Large]).subscribe(result => {
      if (result.matches) {
        this.collapsed = false;
      }
    });
  }

  ngOnInit() {
    // this.getUserEnvironment();
  }

  navigate(item) {
    if (item.type === 'base-route') {
      this.router.navigate([item.call], { relativeTo: this.route.parent });
    }
    if (item.type === 'cluster-route') {
      this.router.navigate([item.call], { relativeTo: this.route });
    }
    if (item.type === 'link') {
      window.open(item.type, '_self');
    }
    if (item.type === 'event'){
      this.event.emit(item.title);
    }
  }

  getUserEnvironment() {
    this.navbarService.userEnvironment().subscribe((data: any) => {
      this.isExternalUser = data.isExternal;
    });
  }
  openExternalLink(item) {
    if (item.disabled) {
      return;
    }
    if (item.hasOwnProperty('call')) {
      window.open(item.call, '_blank');
      // if (this.isExternalUser) {
      //   this.confirmService.open({
      //     text: `
      //     OEC Services are available from Workspace. Please use your Workspace to access project resources.
      //     <a class="fs-16 d-block mt-4" href="http://wiki.openearth.community/connect-to-your-workspace/" target="_blank">
      //     How to connect to your Workspace?</a>
      //     `,
      //     acceptText: null,
      //     rejectText: `Close`,
      //   });
      // } else {
      //   window.open(item.call, '_blank');
      // }
    }
  }
}
