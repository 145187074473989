<div class="modal-header" [ngClass]="getStyleClasses()">
  <h4 class="modal-title">{{ popupTitle }}</h4>
  <!-- <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button> -->
</div>
<div *ngIf="popupContent" class="modal-body">
  {{ popupContent }}
</div>
<div *ngIf="popupHTMLContent" class="modal-body" [innerHTML]="popupHTMLContent"></div>
<div *ngIf="!popupContent && !popupHTMLContent && !componentName" class="modal-body">
  <ng-content select="[modal-body]"></ng-content>
</div>
<div class="modal-body pl-0 pr-0" [ngClass]="getStyleClasses()">
  <div #container></div>
</div>
<div class="modal-footer" [ngClass]="getStyleClasses()" *ngIf="showFooter">
  <ng-content select="[modal-button]"></ng-content>
  <a 
    ds365Button
    status="dark"
    class="mr-1" (click)="activeModal.close('close')">Close</a>
</div>
