import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'ds365-custom-alert-box',
  template: `
    <div class="container mt-100">
      <div *ngIf="hasSuccess" class="row">
        <div class="col">
            <h2 class="text-white multiline-text">Following user(s) were deleted successfully:</h2>
            <div class="mt-3" *ngIf="params.dataToDisplay.length>0">
              <ng-container class="text-center" *ngFor="let user of params.dataToDisplay">
              <ng-container *ngIf="user.status !== 'failed'">
                <p><span class="text-white text-center">{{'  '+user?.username}}</span></p>
                </ng-container>
              </ng-container>
            </div>
        </div>
      </div>
      <div *ngIf="hasFailed" class="row mt-3">
      <div class="col">
          <h2 class="text-white multiline-text">Failed to delete the following users:</h2>
          <div class="mt-3" *ngIf="params.dataToDisplay.length>0">
            <ng-container class="text-center" *ngFor="let user of params.dataToDisplay">
            <ng-container *ngIf="user.status === 'failed'">
              <p><span class="text-white text-center">{{'  '+user?.username}}</span></p>
              </ng-container>
            </ng-container>
          </div>
      </div>
    </div>
    <div class="row mt-3">
    <div class="col">
        <button ds365Button status="white" [bordered]="true" id="confirm-rejects" [fullWidth]="true" (click)="rejected()">
        Close
        </button>
    </div>
    </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      span {
        font-size: 20px;
      }
      button {
        max-width: 250px !important;
        margin: 1rem auto !important;
      }
      .multiline-text {
        white-space: pre-line;
      }
      .form-control {
        width: 100%;
        min-height: 150px;
      }
      .mt-100 {
        margin-top: 100px;
      }

      @media only screen and (max-width: 640px) and (min-width: 320px) {
        .multiline-text {
          font-size: 14px !important;
          margin-top: 100px;
        }
      }
      @media (min-width: 768px) {
        .form-control {
          min-width: 600px;
        }
      }
    `,
  ],
})
export class CustomAlertBoxComponent {
  public form: FormGroup;
  public hasFailed = false;
  public hasSuccess = false;
  constructor(private overlayRef: OverlayRef, @Inject(CONFIRM_BOX_PARAMS) public params: any, private fb: FormBuilder) {
    if (params.dataToDisplay.some(e => e.status === 'failed')) {
      this.hasFailed = true;
    }
    if (params.dataToDisplay.some(e => e.status === 'success')) {
      this.hasSuccess = true;
    }
  }

  inputText = '';


  rejected() {
    this.params.action.next(false);
    this.overlayRef.dispose();
  }
}
