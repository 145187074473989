import { Directive, HostListener, Input } from '@angular/core';
import { StepperComponent } from './stepper.component';

@Directive({
  selector: 'button[ds365StepperNext]'
})
export class StepperNextDirective  {

  constructor(public _stepper: StepperComponent) { }

  @HostListener('click')
  _handleClick() {
    this._stepper.next();
  }
  
}