import { Component, Inject } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { find, values } from 'lodash';
import { CompanyApprovalService } from 'app/modules/approval/components/company-approval/company-approval.service';
import { HttpClient, HttpErrorResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { map, switchMap,tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Component({
  selector: 'ds365-custom-confirm-box',
  template: `
    <div class="container mt-100">
      <div class="row">
        <div class="col">
          <h4 class="text-white text-center multiline-text" [innerHTML]="params.text"></h4>
          <div class="row mt-5">
            <div class="col-6">
            <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
            <label for="ta-remark">Enter SAP Ship To<a class="ml-2 nav-link d-inline p-0 cursor-pointer">
            <span ngbTooltip="SAP Ship To must be 7 digits long and unique. No special characters or alphbets allowed">
              <ds365-icon svgName="information" svgSize="16" status='white'></ds365-icon>
            </span>
          </a><span class="text-danger"> *</span></label>

              <ds365-input
              [id]="'sapReferenceNumber'"
              [group]="form"
              controlName="sapReferenceNumber"
              [maxLength]="7"
              placeholder="Only 7 digits"
              [omitSpecialCharacters]=true
              [errorMessage]= "sapErrorMessage"
            >
            </ds365-input>
            <small class="form-text text-light">&nbsp;</small>
            </div>
        <div class="col-6">
            <ds365-checkbox
            [id]="'autoApprove'"
            [group]="form"
            controlName="autoApprove"
          >
            Auto-approve its employees
          </ds365-checkbox>
        </div>
          </div>
          <div class="row">
            <div class="col-6">
              <ds365-select
                  [id]="'halRegion'"
                  [fg]="form"
                  [items]="halRegions"
                  [control]="form.get('halRegion')"
                  noneSelectedText="Select HAL region from the list"
                  optString="name"
                  optKey="code"
                >
                  <option disabled  selected value firstOption>Select HAL region from the list</option>
                  <ng-container label>HAL Region
                  <a class="ml-2 nav-link d-inline p-0 cursor-pointer">
                    <span ngbTooltip="HAL region administers company's pricing, product availability, CoS and invoicing.">
                      <ds365-icon svgName="information" svgSize="16" status='white'></ds365-icon>
                    </span>
                  </a>
                  </ng-container>
              </ds365-select>
            </div>
            <div class="col-6">
            <ds365-select
                  [id]="'accountManager'"
                  [fg]="form"
                  [items]="accountManagers"
                  [control]="form.get('accountManager')"
                  noneSelectedText="Select Account Manager from the list"
                  optString="name"
                  optKey="username"
                >
                  <option disabled  selected value firstOption>Select Account manager from the list</option>
                  <ng-container label>Account Manager
                  </ng-container>
                </ds365-select>
            </div>
          </div>
          <a *ngIf="params.url" [href]="params.url" target="_blank">{{ params.linkText }}</a>
          <div class="remark-container">
            <label for="ta-remark">Remarks<span class="text-danger"> *</span></label>
            <ds365-text-area
              id="ta-remark"
              [group]="form"
              controlName="remark"
              requiredMsg="Remark is required"
              [maxLength]="1024"
              placeholder="{{params.placeholder}}"
              [rows]="4">
              <ng-container note><i>{{ params.helpText }}</i></ng-container>
            </ds365-text-area>
          </div>
          <div class="clearfix"></div>
          <div class="d-flex justify-content-around">
          <div class="row-6" >
            <button
              *ngIf="params.acceptText"
              [disabled]="!form.valid"
              ds365Button
              [space]="true"
              status="secondary"
              id="confirm-accepts"
              [fullWidth]="false"
              (click)="accepted()"
            >
              {{ params.acceptText }}
            </button>

            <button ds365Button status="secondary" id="confirm-rejects" [space]="true" [fullWidth]="false" (click)="rejected()">
              {{ params.rejectText }}
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
  `,
  styles: [
    `
      :host {
        display: block;
      }
      button {
        max-width: 250px !important;
        margin: 1rem auto !important;
      }
      .multiline-text {
        white-space: pre-line;
      }
      .form-control {
        width: 100%;
        min-height: 150px;
      }
      .mt-100 {
        margin-top: 100px;
        height: auto;
      }
      textarea {
        border: 0px;
        width: 100%;
        height: 100%;
      }
      .remark-container {
        min-width: 600px;
        text-align: left;
      }
      .remark-container label {
        float: none;
      }
      textarea:focus,
      textarea:active {
        border: 0px;
        outline: none;
      }
      @media only screen and (max-width: 640px) and (min-width: 320px) {
        .multiline-text {
          font-size: 14px !important;
          margin-top: 100px;
        }
      }
      @media (min-width: 768px) {
        .form-control {
          min-width: 600px;
        }
      }
    `,
  ],
})
export class CustomConfirmBoxComponent {
  public form: FormGroup;
  public halRegions: any;
  public accountManagers: any;
  public companies: any;
  public sapErrorMessage: string = '';
  constructor(private overlayRef: OverlayRef, @Inject(CONFIRM_BOX_PARAMS) public params: any, private fb: FormBuilder, private http: HttpClient) {
    this.form = this.fb.group({
      sapReferenceNumber: [null, [Validators.required, Validators.minLength(7), Validators.pattern('^[0-9]*')]],
      autoApprove: true,
      remark: [null, [Validators.required]],
      halRegion: ['', Validators.required],
      accountManager: ['', Validators.required],
    });
    this.halRegions = this.params.halRegions;
    this.halRegions.forEach(item => {
      item.selected = false;
    });
    if (this.params.halRegion) {
      find(this.halRegions, { code: this.params.halRegion }).selected = true;
    }
    this.accountManagers = this.params.accountManagers;
    this.accountManagers.forEach(element => {
      element.selected = false;
    });
    if (this.params.accountManager) {
      let am = this.params.accountManager.split(",", 1)
      find(this.accountManagers, { name: am[0] }).selected = true;
    }

    this.form.get('sapReferenceNumber').valueChanges.subscribe(x => {
      this.sapErrorMessage = '';
   })
  }

  accepted() {
  let value=this.sapExists(this.form.value.sapReferenceNumber);
  value.subscribe((exists)=>{
   if(!exists){
    if (this.params.isInput){
      const output = {
        remark: this.form.value.remark,
        provided_license: 'yes',  // Provide 5E002 License
        expiry_date: null,
        approval_url: null,
        sap_reference_number: this.form.value.sapReferenceNumber,
        auto_approve: this.form.value.autoApprove,
        hal_region: this.form.value.halRegion,
        account_manager: this.form.value.accountManager,
      };
      this.params.action.next(output);
                            }
    else {
    this.params.action.next(true);
         } 
         this.overlayRef.dispose();
            }   
  else{
     this.form.controls['sapReferenceNumber'].setErrors({invalid: true});
     this.sapErrorMessage='Sap Ship To Number already exists.';
      }});
  }

  rejected() {
    this.params.action.next(false);
    this.overlayRef.dispose();
  }

  sapExists(sap){
    return this.http.get(`/companies/sap/${sap}`)
      .pipe(switchMap((value: any[]) => {
        if (value.length === 0) {
          return of(false);
        } else {
          return of(true);
        }
      }));
          }
}
