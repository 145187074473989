<date-range-picker [options]="options" [instanceId]="id" (rangeSelected)="rangeSelected($event)" (datepickerReset)="reset($event)" #dateRangePicker>
  <div class="form-group" [formGroup]="group">
    <label [attr.for]="id">
      <ng-content select="[label]"></ng-content>
      <span class="text-danger" *ngIf="required"> *</span>
    </label>
    <input
      [id]="id"
      readonly 
      class="form-control"
      type="text"
      [name]="controlName"
      [value]="dateRangePicker.range"
      [placeholder]="placeholder"
    >
    <i class="fa fa-calendar" (click)="open()" aria-hidden="true"></i>
  </div>
</date-range-picker>

<!-- <date-range-picker  [parentFormGroup]="group" [controlName]="controlName"></date-range-picker> -->
