import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BannerComponent } from './banner.component';
import { BannerWizardButtonsComponent } from './components/wizard-buttons.component';
import { BannerWizardNavComponent } from './components/wizard-nav.component';

const COMPONENTS = [
  BannerComponent,
  BannerWizardButtonsComponent,
  BannerWizardNavComponent,
];
@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule],
  exports: [...COMPONENTS],
})
export class BannerModule {}
