import { Component } from '@angular/core';

@Component({
  selector: 'ds365-loader',
  template: `
    <img
      src="/assets/images/loading.gif"
      class="w-100 mx-auto d-block"
      alt="Loading!"
    />
    <div class="text-white mx-auto d-block text-center">Please wait...</div>
  `,
})
export class LoaderComponent {
  constructor() {}
}
