import {
  AfterViewInit,
  ChangeDetectorRef,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Renderer2,
  Directive,
} from '@angular/core';

import { BSStatus, BSSize } from '../../services';

@Directive({
  selector: '[ds365Button]',
})
export class ButtonDirective implements AfterViewInit {
  @Input() id: string;
  @Input() size: BSSize;
  @Input() status: BSStatus = 'primary';
  @Input() bordered = false;

  @HostBinding('class.btn')
  get btn(): boolean {
    return true;
  }

  @Input()
  @HostBinding('class.btn-block')
  get fullWidth(): boolean {
    return this._fullWidth;
  }
  set fullWidth(value: boolean) {
    this._fullWidth = value;
  }
  private _fullWidth = false;

  @Input()
  @HostBinding('attr.aria-disabled')
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = value;
    this.renderer.setProperty(
      this.hostElement.nativeElement,
      'disabled',
      this.disabled
    );
  }
  private _disabled = false;

  @Input()
  @HostBinding('class.mr-1')
  get space() {
    return this._space;
  }
  set space(value: boolean) {
    this._space = value;
  }
  private _space = false;

  @HostBinding('class.btn-sm')
  get tiny() {
    return this.size === 'sm';
  }

  @HostBinding('class.btn-lg')
  get small() {
    return this.size === 'lg';
  }

  @HostBinding('class.btn-primary')
  get primary() {
    return this.status === 'primary';
  }

  @HostBinding('class.btn-secondary')
  @HostBinding('class.text-white')
  get secondary() {
    return this.status === 'secondary';
  }

  @HostBinding('class.btn-success')
  get success() {
    return this.status === 'success';
  }

  @HostBinding('class.btn-danger')
  get danger() {
    return this.status === 'danger';
  }

  @HostBinding('class.btn-warning')
  get warning() {
    return this.status === 'warning';
  }

  @HostBinding('class.btn-info')
  get info() {
    return this.status === 'info';
  }

  @HostBinding('class.btn-light')
  get light() {
    return this.status === 'light';
  }

  @HostBinding('class.btn-dark')
  get dark() {
    return this.status === 'dark';
  }

  @HostBinding('class.bg-white')
  get white() {
    return this.status === 'white' && !this.bordered;
  }

  @HostBinding('class.border.border-white')
  get whiteOutline() {
    return this.status === 'white' && this.bordered;
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  constructor(
    protected renderer: Renderer2,
    protected hostElement: ElementRef<HTMLElement>,
    protected cd: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    setTimeout(() => {
      this.cd.markForCheck();
    });
  }

  protected get iconElement() {
    const el = this.hostElement.nativeElement;
    return el.querySelector('ds365-icon');
  }
}
