import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output } from '@angular/core';
import { NavbarItem } from './navbars.model';
import { AppConfigurationsService } from '@core/services';
import { AlertService } from '../alert';
import { AuthService } from '@core/auth';
import { NavbarService } from './services/navbar.service';
import { UserService } from '@core/services/user.service';
import { CommunicationService } from '@core/services/communication.service';
import { CompanyService } from 'app/modules/company/company.service';
import { ClusterService } from 'app/modules/cluster/cluster.service';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { TitleCasePipe } from '@angular/common';
import { MessageService } from '@core/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupComponent } from '../popup/popup.component';
import { AccountManagerService } from 'app/modules/account-manager/account-manager.service';
import { isEmpty } from 'lodash';



@Component({
  selector: 'ds365-navbars',
  templateUrl: './navbars.component.html',
  styleUrls: ['./navbars.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarsComponent implements OnInit {
  @Input()
  items: NavbarItem[] = [];

  @Output() displayNotifications = new EventEmitter();
  @Input() count = 0;
  @Input()
  userItems: NavbarItem[] = [];

  isNavBar = true;

  JSON = JSON;

  userProfileData = {
    title: '',
    icon: '',
    children: [
      {
        title: 'Log Out',
        icon: 'logout',
        tooltip: 'Log Out',
        type: 'command',
        call: 'logout',
        target: '_self',
        visibility: 'user-nav',
      }
    ],
  };

  launchWorkSpace = {
    title: 'Launch Workspace',
    icon: 'workspace',
    tooltip: 'Launch Workspace Web Client',
    type: 'link',
    target: '_blank',
  };


  userFullName = '';
  noOfProjects = 0;
  isExternalUser = false;
  cartCount = 0;
  switchUI = true;
  constructor(
    public application: AppConfigurationsService,
    public authService: AuthService,
    public alertService: AlertService,
    public navbarService: NavbarService,
    public userService: UserService,
    public companyService: CompanyService,
    private communicationService: CommunicationService,
    public clusterService: ClusterService,
    private titlecasePipe: TitleCasePipe,
    private messageService: MessageService,
    private router: Router,
    private modalService: NgbModal,
    private accountManagerService: AccountManagerService  ) {
    const currentUrl = new URL(window.location.href);
    const switchUIParam = currentUrl.searchParams.get('switchUI');
    if (!localStorage.getItem('switchUI')) {
      if (switchUIParam && switchUIParam === 'false') {
        localStorage.setItem('switchUI', 'false');
        this.switchUI = false;
      }
    }

  }

  ngOnInit() {
    if (localStorage.getItem('switchUI')) {
      this.switchUI = false;
    }
    this.authService.isAuthenticated().subscribe(data => {
      if (!data){
        this.removeCartAndSessionStorage();
      }
    })
    setTimeout(() => {
      this.authService
        .getToken()
        .pipe()
        .subscribe((data: any) => {
          if (data && data.payload) {
            this.userProfileData.title = `${data.payload.given_name} ${data.payload.family_name.substring(0, 1)}.`;
            if (data.getProp('realm_access').roles.includes('ds365-company-representative')) {
              this.userProfileData.icon = 'company-representative';
            }
            let clusterPackageId;
            let userIndentity = {
              name: data.payload.given_name + ' ' + data.payload.family_name,
              email: '',
              username: '',
              first_name: '',
              last_name: '',
              company: '',
              company_unique_name: ''
            };
            let userCluster = {
              cloudInstance: '',
              package: '',
            };


            this.userService
              .getProfile()
              .pipe(
                switchMap((data: any) => {
                  userIndentity.email = data.email;
                  userIndentity.username = data.username;
                  userIndentity.first_name = data.first_name;
                  userIndentity.last_name = data.last_name;
                  return this.companyService.getCompany(data.company_id);
                }),
              switchMap((company: any) => {
                userIndentity.company = company.name;
                userIndentity.company_unique_name = company.unique_name;
                this.clearCartForUser(userIndentity);
                localStorage.setItem('user_identity', JSON.stringify(userIndentity));
                  this.userProfileData.children.unshift(
                    {
                      title: 'Change Password',
                      icon: 'password',
                      tooltip: 'Change Password',
                      type: 'command',
                      call: 'changePassword',
                      target: '_self',
                      visibility: 'user-nav',
                    },
                  )
                return this.accountManagerService.getProfileGroups();
              })).subscribe(groups => {
                groups = groups.map(group => group.name);
                if ((!this.accountManagerService.isAccountManager(groups) && !this.accountManagerService.isCOS(groups)
                  && !this.accountManagerService.isSupport(groups) && !this.accountManagerService.isUserWithRole('ds365-admin') && !this.accountManagerService.isMarketing(groups)) && this.switchUI) {
                  location.href =
                    this.application.configs.application_url.replace('console', 'essentials') + location.href.replace(location.origin, '');
                } else {
                  this.clusterService
                  .getMemberCluster()
                  .pipe(
                    switchMap((response: any) => {
                      if (!isEmpty(response)) {
                        clusterPackageId = response.cluster_package_id;
                        userCluster.cloudInstance = response.cluster_display_name;
                        return this.clusterService.getProductsByCluster(response.cluster_id);
                      } else {
                        return of([]);
                      }
                    })
                  )
                  .subscribe((products: any[]) => {
                    if (products.length > 0) {
                      const applications = products.map(p => {
                        return {
                          id: p.id,
                          label: `${p.package_display_name}`,
                          type: 'application',
                        };
                      });
                      userCluster.package = this.titlecasePipe.transform(
                        applications.find(a => a.id === clusterPackageId).label
                      );
                    }
                    localStorage.setItem('user_cluster', JSON.stringify(userCluster));
                    this.communicationService.emitChange('preloading-completed');
                  });
                }
              })
          }
        });
    }, 2500);

    this.communicationService.changeEmitted$.subscribe(action => {
      if (action === 'logout') {
        //clear chatbot data

        localStorage.removeItem('user_identity');
        localStorage.removeItem('user_cluster');
        localStorage.removeItem('cart');
        localStorage.removeItem('switchUI');
        this.authService.logout();
      }
      if (action === 'changePassword') {
         this.router.navigate(['user/change-password']);
      }
      if (action === 'terms-agreement-enter') {
        this.isNavBar = false;
      }
      if (action === 'terms-agreement-leave') {
        this.isNavBar = true;
      }
      if (action === 'highlight-getting-started'){
        const gettingStarted = this.items.find(item => item.title === 'Getting Started');
        gettingStarted.highlight = true;
        setTimeout(() => {
          gettingStarted.highlight = false;
        }, 5000)
      }
      
    });

    this.userProjectsCount();
    this.messageService.subscribeMessage().subscribe(cartCount => {
      if (cartCount.subscriberName === 'Navbars Component'){
        this.cartCount = cartCount.content
      }
    })

    let cart;
    if (localStorage.getItem('cart')) {
      cart = JSON.parse(localStorage.getItem('cart'));
      this.cartCount = cart.items.length;
    }
  }

  openFirstSignInPopup() {
    const modalRef = this.modalService.open(PopupComponent, { size: 'lg' });
    modalRef.componentInstance.popupTitle = 'Welcome to DecisionSpace® 365 Essentials'
    modalRef.componentInstance.componentName = 'getStartedWizard';
    modalRef.componentInstance.showFooter = false;
    modalRef.componentInstance.background = 'secondary';
  }

  showAlert() {
    this.alertService.warn();
  }
  clearCartForUser(userIdentity){
    const user = JSON.parse(localStorage.getItem('user_identity'));
    if (user) {
      if (user.email !== userIdentity.email){
        this.removeCartAndSessionStorage()
      }
    }
  }

  removeCartAndSessionStorage(){
      localStorage.removeItem('cart');
      localStorage.removeItem('switchUI');
      this.cartCount = 0;
  }
  getUserEnvironment() {
    this.navbarService.userEnvironment().subscribe((data: any) => {
      this.isExternalUser = data.isExternal;
    });
  }
  userProjectsCount() {}

  doNothing(e){
    e.preventDefault();
  }

onShowNotifications() {
    this.displayNotifications.emit();
  }
}
