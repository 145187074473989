import { Directive, HostListener, Input } from '@angular/core';
import { StepperComponent } from './stepper.component';

@Directive({
  selector: 'button[ds365StepperPrevious]'
})
export class StepperPreviousDirective  {

  constructor(public _stepper: StepperComponent) {}

  @HostListener('click')
  _handleClick() {
    this._stepper.previous();
  }
  
}