import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { VIDEO_PLAYER_DATA } from './video-player.token';

declare var kWidget;

@Component({
  selector: 'ds365-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VideoPlayerComponent implements OnInit {
  constructor(private overlayRef: OverlayRef, @Inject(VIDEO_PLAYER_DATA) public video: any) {}

  ngOnInit() {  
    kWidget.embed({
      targetId: 'kvp-player',
      wid: '_1781281',
      uiconf_id: '41332911',
      entry_id: this.video.videoId,
      flashvars: {
        autoPlay: true,
      },
      params: {
        wmode: 'transparent',
        allowFullScreen: true,
      },
    });
  }

  stop() {
    this.overlayRef.dispose();
  }
}
