import { ClusterType } from "@shared/cluster-types.constant";
import * as _ from "lodash";

export class ClusterMember {
  id: string;
  name: string;
  email: string;
  status: string;
  reason: string;
  application: string;
  image: string;
  username: string;
  role: string;
  userId: string;
  applicationRole: string;
  workspace_state: string;
  packageAccess: string;
  packageId:string;
  subscriptionDetailId: number;
  constructor(projectMemberBackend, applications, cluster) {
    this.id = projectMemberBackend.id;
    this.name = projectMemberBackend.first_name + ' ' + projectMemberBackend.last_name;
    this.email = projectMemberBackend.email;
    this.status = projectMemberBackend.hasOwnProperty('request_status') ? 'pending' : 'active';
    this.reason = projectMemberBackend.remark;
    if (applications.find((a) => a.id === projectMemberBackend.cluster_package_id)) {
      const app = applications.find((a) => a.id === projectMemberBackend.cluster_package_id);
      this.application = app.label;
      this.image = app.image;
      this.subscriptionDetailId = app.subscriptionDetailId;
    }
    this.username = projectMemberBackend.username;
    this.role = projectMemberBackend.access_type || 'Member';
    this.userId = projectMemberBackend.user_id;
    this.workspace_state = projectMemberBackend.workspace_state;
    this.packageId = projectMemberBackend.package_id;
    if (projectMemberBackend.hasOwnProperty('request_status') && projectMemberBackend.application_role) {
      this.applicationRole = projectMemberBackend.application_role;
    } else {
      this.applicationRole = projectMemberBackend.package_role || ((cluster.created_by_id == this.userId) ? 'Administrator' : 'User');
    }
    this.packageAccess = projectMemberBackend.package_access;
  }
}

export class Cluster {
  creatorFirstName: string;
  companyId: number;
  creatorUsername: string;
  imageUrl: string[];
  description: string;
  remark: string;
  displayName: string;
  type: string;
  creatorCompanyId: number;
  uniqueName: string;
  creator: boolean;
  createdOn: Date;
  creatorEmail: string;
  creatorStatus: string;
  companyName: string;
  isStoppable: boolean;
  id: number;
  createdById: number;
  deploymentRegion: string;
  deploymentRegionName: string;
  creatorLastName: string;
  status: string;
  access: string;
  access_type: string;
  canAccess: string;
  membersCount: number;
  packages: any[];
  packageNames: string;
  totalMember: number;
  totalRequests: number;
  totalJoined: number;
  isTry: boolean;
  isCommercial: boolean;
  cluster_type: string;
  subscriptionId: number;
  halRegionCode: string;
  halRegionName: string;
  isHibernated: boolean;

  constructor(cluster: any) {
    this.creatorFirstName = cluster.creator_first_name;
    this.companyId = cluster.company_id;
    this.creatorUsername = cluster.creator_username;
    this.imageUrl = cluster.image_url;
    this.description = cluster.description;
    this.remark = cluster.remark;
    this.displayName = cluster.display_name;
    this.type = cluster.cluster_type;
    this.creatorCompanyId = cluster.creator_company_id;
    this.uniqueName = cluster.unique_name;
    this.createdOn = cluster.created_on;
    this.creatorEmail = cluster.creator_email;
    this.creatorStatus = cluster.creator_status;
    this.companyName = cluster.company_name;
    this.isStoppable = cluster.is_stoppable;
    this.id = cluster.id;
    this.createdById = cluster.created_by_id;
    this.deploymentRegion = cluster.deployment_region;
    this.deploymentRegionName = cluster.deployment_region_name;
    this.creatorLastName = cluster.creator_last_name;
    this.status = cluster.status;
    this.access = cluster.access;
    this.access_type = cluster.access_type;
    this.canAccess = cluster.canAccess;
    this.membersCount = cluster.membersCount;
    this.packages = cluster.packages;
    this.packageNames = cluster.packages_names;
    this.creator = cluster.creator;
    this.totalMember = cluster.totalMember;
    this.totalRequests = cluster.totalRequests;
    this.totalJoined = cluster.totalJoined;
    this.imageUrl = [];
    this.isTry = false;
    this.halRegionCode = cluster.hal_region_code;
    this.halRegionName = cluster.hal_region_name;
    for (const pkg of this.packages) {
      if (pkg.image_url && this.imageUrl.indexOf(pkg.image_url) === -1) {
        this.imageUrl.push(pkg.image_url);
      }
    }
    this.cluster_type = cluster.cluster_type;
    this.isCommercial = cluster.cluster_type !== ClusterType.TRAINING && 
    cluster.cluster_type !== ClusterType.TRIAL ;
    this.subscriptionId = cluster.company_subscription_id;
    this.isHibernated = cluster.is_hibernated;
  }
}

export class SubscriptionDetail {
  company_subscription_id: number;
  max_user: number;
  expiry_date: Date;
  remark: string;
  id: number;
  package_id: number;
  sap_doc_no: string;
  min_user: number;
  start_date: Date;
  status: string;
}

export class SubscriptionRenew {
  renew: number;
  backup: number;
}
