<div
  class="custom-control custom-checkbox"
  [ngClass]="{ 'custom-control-inline': inline }"
  [formGroup]="group"
>
  <input
    class="custom-control-input"
    type="checkbox"
    [attr.id]="id"
    [value]="value"
    [formControlName]="controlName"
    ds365IsInvalid
  />
  <label class="custom-control-label" [attr.for]="id">
    <ng-content></ng-content>
  </label>
</div>
