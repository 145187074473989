import { Component, Input, OnChanges } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'ds365-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent implements OnChanges {
  private _background = '';
  private _text = '';
  public bannerHeight = '60px';

  @Input()
  set background(value: string) {
    this._background = `bg-${value}`;
  }

  get background(): string {
    return this._background;
  }

  @Input()
  set text(value: string) {
    this._text = `text-${value}`;
  }

  get text(): string {
    return this._text;
  }

  @Input()
  public fixed = false;

  // TODO: Allow user to set screen specific height
  @Input()
  public grow = false;

  constructor(private breakpointObserver: BreakpointObserver) {}

  ngOnChanges() {
    if (this.grow) {
      this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]).subscribe(result => {
        if (result.matches) {
          this.bannerHeight = '120px';
        } else {
          this.bannerHeight = '60px';
        }
      });
    }
  }

  public getStyleClasses() {
    return {
      [this._background]: true,
      [this.text]: true,
      'position-fixed': this.fixed,
    };
  }
}
