<ng-container >
  <div class="paginator-container mb-60" [ngClass]="getClassPosition()">
    <div class="paginator-wrapper" *ngIf="!_viewAllMode && _pages > 1" >
      <div ngbDropdown class="d-inline-block">
        <div ngbDropdownMenu>
          <ng-container *ngFor="let size of pageSizeOptions">
            <a style="cursor: pointer;" ngbDropdownItem (click)="changePageSize(size)">{{ size }}</a>
          </ng-container>
        </div>
      </div>
      <button
        ds365Button
        status="primary"
        class="mr-2 mb-2"
        size="sm"
        [disabled]="_currentPage === 1"
        (click)="goToPage(_currentPage - 1)"
      >
        <ds365-icon svgName="arrow-left" svgSize="10" status="white"></ds365-icon>
        <span class="align-middle pr-2 pl-2"> View previous</span>
      </button>
      <span class="paginator-link">
        <ng-container *ngFor="let link of _pageLinks">
          <span
            (click)="goToPage(link.page)"
            class="ml-2 mr-2 paginator-link__item"
            [ngClass]="link.page === _currentPage ? 'paginator-link__item--active' : ''"
          ></span>
        </ng-container>
      </span>
      <button
        ds365Button
        status="primary"
        class="ml-2 mr-2 mb-2"
        size="sm"
        [disabled]="_currentPage === _pages"
        (click)="goToPage(_currentPage + 1)"
      >
        <span class="align-middle pr-2 pl-2">View next</span>
        <ds365-icon svgName="arrow_right" svgSize="10" status="white"></ds365-icon>
      </button>
      <button *ngIf="_pages > 1 && count < 500" ds365Button status="primary" size="sm" class="ml-2 mb-2" (click)="viewAll()">
        <span class="align-middle pr-2 pl-2">View All</span>
      </button>
    </div>
    <button *ngIf="_viewAllMode" ds365Button status="primary" size="sm" class="ml-2 mb-2" (click)="reset()">
      <span class="align-middle pr-2 pl-2">Reset</span>
    </button>
  </div>
</ng-container>


