import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { ControlBase } from '../control';

@Component({
  selector: 'ds365-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
})
export class MultiSelectComponent implements OnInit, OnChanges, ControlBase {
  tagForm: FormGroup;

  @Input() items: any[];
  @Input() group: FormGroup;
  @Input() control: FormControl;
  @Input() id: string;
  @Input() placeholder: string;
  @Input() liveSearch = false;
  @Input() selectedDisplay = 4;
  @Input() liveSearchPlaceholder: string = null;
  @Input() liveSearchStyle: 'contains' | 'startsWith' = 'contains';
  @Input() disabled: boolean;

  selection: string;
  preSelection: any[];
  query: FormControl

  get classes() {
    const classObj = {
      'form-control': true,
    };
    return classObj;
  }

  constructor(private fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.hasOwnProperty('items')) {
      this.addCheckboxes();
    }
  }

  ngOnInit() {
    this.preSelection = this.control.value || [];

    this.query = new FormControl();

    this.query.valueChanges.subscribe(filter => {
      this._filterResults(filter);
    });

    this.tagForm = this.fb.group({
      tags: new FormArray([]),
    });

    this.tagForm.valueChanges.subscribe(changes => {
      const selectedDomains = this.items.filter((v, i) => {
        if (changes.tags[i]) {
          return true;
        } else {
          return false;
        }
      });
      if (this.group && this.control) {
        const model = selectedDomains.map(d => d.id);
        const display = selectedDomains.map(d => d.name).join(', ');
        this.selection = selectedDomains.length >= this.selectedDisplay ? `${selectedDomains.length} checked` : display;
        this.control.setValue(model.length !== 0 ? model : null);
      }
    });
    this.addCheckboxes();
  }

  get isInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  private addCheckboxes() {
    if (!this.tagForm || this.items && this.items.length === 0) {
      return;
    }

    if (this.items && this.items.length >= 1) {
      this.items.forEach((o, i) => {
        const control = new FormControl(this.preSelection.indexOf(o.id) !== -1);
        (this.tagForm.controls.tags as FormArray).push(control);
      });
    }
  }

  toggled(event) {
    if (!this.disabled) {
      this.query.reset();
    }
  }

  _filterResults(filter) {
    const contains = this.liveSearchStyle === 'contains';

    if (!filter || filter === '') {
      this.items.forEach(item => {
        item.filtered = true;
      });
    } else {
      this.items.forEach(item => {
        if (item.ignoreSearch) {
          item.filtered = true;;
        }
        item.filtered = contains
          ? item.name.toLowerCase().includes(filter.toLowerCase())
          : item.name.toLowerCase().startsWith(filter.toLowerCase());
      });
    }
  }
}
