import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation, OnChanges } from '@angular/core';
import { BadgePropertiesModel } from './badge.model';

@Component({
  selector: 'ds365-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BadgeComponent implements OnInit, OnChanges {
  @Output() selectedValue = new EventEmitter();

  @Input()
  dropdownStyle = {};

  @Input()
  labelClass = '';

  @Input()
  labelStyle = {};

  @Input()
  badgeValue: string;

  @Input()
  disableButton = false;

  @Input() model: any;

  @Input() buttonIconFill = {
    fill: 'white',
  };

  @Input() whiteDropdownArrow = true;

  @Input() showCarret = true;

  @Input() props: BadgePropertiesModel

  constructor() {}

  itemClick(event, item: any) {
    if (item.disabled) {
      event.preventDefault();
      return;
    }

    if (item.url) {
      this.selectedValue.emit(item.url);
      return;
    } else if (item.command) {
      if (this.model.reflectSelection) {
        item.command();
      } else {
        item.command();
      }
    } else {
      const data = {
        action: item.label,
        state: item.labelWhenSelected ? item.labelWhenSelected : item.label,
      };
      this.selectedValue.emit(data);
    }
  }

  ngOnInit() {
    this.setDefaultValue();
  }

  ngOnChanges(changes) {
    this.setDefaultValue();
  }

  setDefaultValue() {
    if (this.model) {
      if (this.model.menu && this.model.hasDefault) {
        this.model.menu.forEach(item => {
          if (item.default) {
            this.props.buttonLabel = item.labelWhenSelected ? item.labelWhenSelected : item.label;
            this.props.buttonColor = item.iconFill.fill;
            this.props.buttonImg = item.icon;
          }
        });
      }
    }
  }
}
