import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { NavbarItem } from '../../navbars.model';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'ds365-inside-link-item',
  template: `
    <span class="nopadding" ngbDropdownItem [disabled]="disabledItem">
      <a
        id="{{ id }}"
        [routerLink]="item.call"
        class="nav-link py-0 mt-10 mb-10 text-nowrap"
        [ngClass]="{
          nopadding: noPadding,
          'text-light disabled': !userService.isUserApproved && item.approvedUserOnly,
          'text-white': userService.isUserApproved || !item.approvedUserOnly
        }"
      >
        <ng-content></ng-content>
      </a>
      <span> </span
    ></span>
  `,
  styles: ['.dropdown-item.disabled{ opacity:0.5}'],
})
export class InsideLinkItemComponent implements OnInit {
  @Input()
  noPadding = false;

  @Input()
  public id = '';

  @Input()
  public item: NavbarItem;

  @Input()
  disabledItem = false;

  constructor(public userService: UserService) {}

  ngOnInit() {}
}
