<div class="form-group" [formGroup]="fg">
  <label class="w-100" [attr.for]="id">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger" *ngIf="isRequired"> *</span>
  </label>
  <div
    [attr.id]="id"
    [ngClass]="{'border border-danger':highlight}"
    class="btn-group ds365-select w-100"
    [class.disabled]="disabled"
    [class.open]="open"
    [ngClass]="{ 'search-select': liveSearch }"
    *ngIf="liveSearch"
  >
    <button
      type="button"
      class="btn dropdown-toggle btn-selectpicker"
      [ds365IsInvalid]="control"
      (click)="toggleOpen()"
      data-toggle="dropdown"
      [class.disabled]="disabled"
      role="button"
      data-id="company"
      [attr.aria-disabled]="disabled"
    >
      <span class="filter-option text-truncate">{{ selection }}</span
      >&nbsp;
      <ds365-icon svgName="arrow-down" svgSize="18" status="white" *ngIf="isInvalid; else invalidIcon"></ds365-icon>
      <ng-template #invalidIcon>
        <ds365-icon svgName="arrow-down" svgSize="18" status="white"></ds365-icon>
      </ng-template>
    </button>
    <div
      class="dropdown-menu"
      [ngClass]="{ 'mt-0': true, 'sticky-opt': !stickyOpt }"
      [class.show]="open"
      role="combobox"
    >
      <div class="bs-searchbox" *ngIf="liveSearch">
        <input
          #search
          class="form-control"
          [formControl]="query"
          type="text"
          autocomplete="off"
          role="textbox"
          aria-label="Search"
          placeholder="{{ liveSearchPlaceholder }}"
        />
      </div>
      <ul class="dropdown-menu inner" [class.show]="open" role="listbox" aria-expanded="false">
        <li
          [attr.data-original-index]="idx"
          *ngFor="let item of filteredItems; let idx = index"
          [class.selected]="item.selected"
          [class.spdivider]="item.divider"
          [ngClass]="{ 'sticky-opt': !stickyOpt }"
          (click)="toggleItem(item)"
        >
          <a
            tabindex="{{ idx }}"
            [attr.data-tokens]="item.name"
            class="bs-option"
            role="option"
            [attr.aria-disabled]="item.disabled"
            [attr.aria-selected]="item.selected"
          >
            <span class="text" [innerHTML]="item.title"></span>
          </a>
        </li>
        <li class="sticky-opt" *ngIf="emptySearch">{{ noneResultsText }} "{{ query.valueChanges | async }}"</li>
      </ul>
    </div>
    <select style="display: none;" tabindex="-98" [attr.multiple]="multiple">
      <option *ngFor="let item of filteredItems" value="{{ item.id }}" [attr.selected]="item.selected ? true : null">
        {{ item.title }}</option
      >
    </select>
  </div>
  <div *ngIf="!liveSearch">
    <select
      [attr.aria-disabled]="disabled"
      [class.disabled]="disabled"
      [attr.id]="id"
      class="custom-select"
      [formControl]="control"
      [ds365IsInvalid]="control"
    >
      <ng-content select="[firstOption]"></ng-content>
      <option *ngFor="let item of filteredItems" value="{{ item.id }}">{{ item.title }} </option>
    </select>
    <small class="form-text text-muted"
      >&nbsp;
      <ng-content select="[hints]"></ng-content>
    </small>
  </div>
</div>
