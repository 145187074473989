import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { HeaderService } from '../header.service';

@Component({
  selector: 'ds365-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  showMore = false;
  loading = false;
  @Input() user;
  @Input() notifications;
  @Output() notificationClose = new EventEmitter();
  @Output() notificationHided = new EventEmitter();
  constructor(private service: HeaderService) {}

  ngOnInit() {}
  markAsRead(id) {
    this.loading = true;
    this.service.hideNotification(this.user.id, id).subscribe(data => {
      this.loading = false;
      this.notifications = this.notifications.filter(item => item.id !== id);
      this.notificationHided.emit(this.notifications);
    });
  }
  onClosed() {
    this.notificationClose.emit(false);
  }
}
