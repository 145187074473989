import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { RouterModule } from '@angular/router';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [SidebarComponent],
  imports: [RouterModule, CommonModule, IconModule],
  exports: [SidebarComponent],
})
export class SidebarModule {}
