import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, of, EMPTY } from 'rxjs';
import { filter, share } from 'rxjs/operators';

import { AuthToken } from '../models/token';
import { TokenStorage } from './token-storage';

@Injectable()
export class TokenService {
  protected token$: BehaviorSubject<AuthToken> = new BehaviorSubject(null);

  constructor(protected tokenStorage: TokenStorage) {
    this.publishStoredToken();
  }

  tokenChange(): Observable<AuthToken> {
    return this.token$.pipe(
      filter(value => !!value),
      share()
    );
  }

  set(token: AuthToken): Observable<null> {
    this.tokenStorage.set(token);
    this.publishStoredToken();
    return EMPTY;
  }

  get(): Observable<AuthToken> {
    const token = this.tokenStorage.get();
    return of(token);
  }

  clear(): Observable<null> {
    this.tokenStorage.clear();
    this.publishStoredToken();
    return EMPTY;
  }

  protected publishStoredToken() {
    this.token$.next(this.tokenStorage.get());
  }
}
