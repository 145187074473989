import { Component } from '@angular/core';
import { AppConfigurationsService } from '@core/services';
import { FooterService } from './footer.service';
import { ActivatedRoute } from '@angular/router';
import { AuthToken } from '@core/auth/models/token';
import { UserService } from '@core/services/user.service';
import { AuthService } from '@core/auth';
import { map } from 'rxjs/operators';
import { GCPTermsAndConditions } from '@ds365/terms-plugin';
import { EVENT_ERROR, EVENT_LOADED } from '@ds365/terms-plugin/dist/defaults';

@Component({
  selector: 'ds365-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  solution: string = '';
  gcpPlugin: any;
  isLoggedIn = false;
  constructor(
    public application: AppConfigurationsService,
    public service: FooterService,
    private route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService
  ) {
    this.route.queryParams.subscribe(params => {
      this.solution = params['solution'] || 'ds365';
    });
  }

  get year() {
    return new Date().getFullYear();
  }

  headers() {
    return this.authService
      .getToken()
      .pipe(
        map((token: AuthToken) => {
          const value = token.getValue();
          if (value) {
            this.isLoggedIn = true;
          }
          return { Authorization: `Bearer ${value}` };
        })
      )
      .toPromise();
  }

  downloadTerms(event: any) {
    event.preventDefault();

    let isPdf = true;

    const options: any = {
      headers: this.headers.bind(this),
    };
    
    this.userService.getConfigurations('termService', 'download').subscribe((configs: any) => {
      if (configs.isEnabled) {
        options.url = configs.baseURL;
        options.ui = configs.ui;
        options.force = configs.force;
        const solutionCode = this.isLoggedIn ? null : this.solution;

        this.gcpPlugin = new GCPTermsAndConditions(options, null);
        this.gcpPlugin.downloadTermsByType(configs.type, isPdf, solutionCode);

        this.gcpPlugin.on(EVENT_LOADED, async (data: any) => {
          //once terms are loaded and returned as pdf
          const terms = data?.detail?.terms || null;
          if (terms) {
            let fileName = this.getTNCFileName();
            this.downloadFileFromBlob(terms, fileName);
          }
        });

        this.gcpPlugin.on(EVENT_ERROR, (data: any) => {});
      }
    });
  }

  private getTNCFileName() {
    return `DecisionSpace 365 TCs.pdf`;
  }
  private downloadFileFromBlob(blob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
