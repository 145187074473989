import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '../services';

@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private _authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this._authService
      .isAuthenticated()
      .toPromise()
      .then(val => {
        if (val) {
          this.router.navigateByUrl('welcome');
          return false;
        }
        return true;
      });
  }
}
