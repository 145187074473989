import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
// TODO: Move to Core
@Injectable()
export class CommunicationService {
  constructor() {}
  public initialLoad = true;
  private emitChangeSource = new Subject<any>();

  changeEmitted$ = this.emitChangeSource.asObservable();

  emitChange(data: any) {
    this.emitChangeSource.next(data);
  }
}
