import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AccountManagerService } from '../account-manager.service';

@Injectable()
export class AccountManagerGroupGuard implements CanActivate {
  constructor(private router: Router, private amService: AccountManagerService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.amService.getProfileGroups().toPromise().then((data) => {
      if (this.amService.isAccountManager(data.map(x => x.name))) {
        return true;
      }
      return false;
    }).catch(error => false);
  }
}
