import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';

@Component({
  selector: 'ds365-chart',
  templateUrl: './chart.component.html',
  styleUrls: [ './chart.component.scss' ]
})
export class ChartComponent implements OnInit {
  @Input() chartData;
  @Input() chartLabels;
  @Input() chartType;
  @Input() chartLegend = true;
  @Input() chartPlugins = [];
  @Input() chartColors: Color[] = [];

  private _chartOptions: (ChartOptions & { responsive: any }) = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'bottom'
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true
          }
        }
      ]
    }
  };
  @Input('chartOptions') 
  set chartOptions(options: ChartOptions) {
    if (options.scales) {
      options.scales = {
        ...this._chartOptions.scales,
        ...options.scales
      }
    }
    this._chartOptions = {
      ...this._chartOptions,
      ...options
    }
  };
  get chartOptions() {
    return this._chartOptions;
  }

  constructor() { }

  ngOnInit() {
  }
}
