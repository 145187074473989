import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'ds365-notification-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  showMore = false;
  @Input() notify;
  @Output() markRead = new EventEmitter();
  constructor() {}

  ngOnInit() {}
  markAsRead(id) {
    this.markRead.emit(id);
  }
}
