import { Injectable, Injector, ComponentRef } from '@angular/core';
import { OverlayRef } from '@angular/cdk/overlay';
import { DS365Overlay } from '../overlay/overlay.service';
import { Observable, Subject } from 'rxjs';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';
import { ConfirmBoxComponent } from './confirm-box.component';
import { CONFIRM_BOX_PARAMS } from './confirm-box.token';
import { ConfirmBoxContent, defaultConfirmBoxContent } from './confirm-box.model';
import { first, filter } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ConfirmBoxService {
  private _confirmation = new Subject<boolean>();
  private params: ConfirmBoxContent;
  private overlayRef: OverlayRef;

  constructor(private ds365Overlay: DS365Overlay, private injector: Injector, private router: Router) {}

  open(params: any, host?: HTMLElement, componentToPort: ComponentType<any> = ConfirmBoxComponent) {
    this.params = { ...defaultConfirmBoxContent, ...params };
    if (!host) {
      this.overlayRef = this.ds365Overlay.createWithDefaultConfig();
    } else {
      this.overlayRef = this.ds365Overlay.createWithDefaultConfig(host);
    }
    const injector = this.createInjector();
    const containerRef: ComponentRef<any> = this.overlayRef.attach(
      new ComponentPortal(componentToPort, null, injector)
    );
    // FIXME: Should be configureable
    this.routeChanged();
    return containerRef;
  }

  close(overlayRef: OverlayRef) {
    overlayRef.dispose();
  }

  routeChanged() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(_ => {
      if (this.overlayRef instanceof OverlayRef) {
        this.overlayRef.dispose();
      }
    });
  }

  onConfirm(): Observable<boolean> {
    this.params.action.pipe(first()).subscribe((action: boolean) => {
      this._confirmation.next(action);
    });
    return this._confirmation.asObservable();
  }
  private createInjector(): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(OverlayRef, this.overlayRef);
    injectionTokens.set(CONFIRM_BOX_PARAMS, this.params);

    return new PortalInjector(this.injector, injectionTokens);
  }
}
