<footer class="border-top">
  <div class="container-fluid footer-container">
    <div class="container d-flex justify-content-center">
      <p class="font-weight-light w-100 footer-wrapper">
        <span class="mr-3">Copyright © {{year}} Halliburton. All Rights Reserved.</span>
        <span>
          <a href="#" (click)="downloadTerms($event)">Terms Of Use</a>
          <span> | </span>
        </span>
        <span>
          <a target="_blank" href="https://www.halliburton.com/en-US/about-us/legal/privacy-policy.html">Privacy Notice</a>
          <span> | </span>
        </span>
        <span>
          <a target="_blank" href="https://www.landmark.solutions/Opt-Out">Opt-Out</a>
          <span> | </span>
        </span>
        <span>
          <a target="_blank" href="https://www.halliburton.com/">Go to Halliburton</a>
        </span>
        <span class="footer-logo">
          <img src="/assets/images/Landmak-Logo-white.png" alt="Landmark Solutions Logo" />
        </span>
      </p>
    </div>
  </div>
</footer>
