import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FooterService {

  constructor(private http: HttpClient) { }

  public downloadLastestTerms(solution_code:string) {
    return this.http.get(`/terms/download?solution_code=${solution_code}`, { responseType: 'blob', headers: { 'Content-Type': 'application/octet-stream' } });
  }
  
}
