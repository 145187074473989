import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@core/auth';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AccountManagerService {
  constructor(
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getUserGroups(username) {
    return this.http.get<any[]>(`users/${username}/groups`);
  }

  public getProfileGroups() {
    return this.http.get<any[]>(`users/group`);
  }

  public isAccountManager(groups: string[]): boolean {
    return this.isUserInGroup(groups, 'Account Manager');
  }

  public findAMRegions(groups: any[], halregions: any[]) {
    return halregions.filter(region => groups.includes(region.am_group));
  }

  public isCOS(groups: string[]): boolean {
    return this.isUserInGroup(groups, 'LMK CoS');
  }
  public isSupport(groups: string[]): boolean {
    return this.isUserInGroup(groups, 'Support');
  }

  public isMarketing(groups: string[]): boolean {
    return this.isUserInGroup(groups, 'Marketing');
  }

  public getHalRegions() {
    return this.http.get<any[]>(`/region/halregion`);
  }

  public isExpiredSubViewer(): boolean {
    return this.isUserWithRole('expired-subscription-viewer');
  }


  public isUserInGroup(userGroups: string[], checkGroup: string): boolean {
    const length = userGroups.length;
    for (let i = 0; i < length; i++) {
      if (userGroups[i].includes(checkGroup)) { return true; }
    }
    return false
  }
  public isUserWithRole(checkRole: string): boolean {
    this.authService
      .getToken()
      .pipe(
        tap((data: any) => {
          if (data && data.payload) {
            if (data.getProp('realm_access').roles.includes(checkRole)) {
              return true;
            }
          }
        })
      )
    return false
  }
}
