import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PageChangedEvent } from './paginator.model';

/**
 * <ds365-paginator
 *  [count]="100"
 *  [pageSizeOptions]="[5, 10, 15, 20]"
 *  (pageChanged)="handler($event)">
 * </ds365-paginator>
 */
@Component({
  selector: 'ds365-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges, OnInit {
  @Output()
  pageChanged: EventEmitter<PageChangedEvent> = new EventEmitter();

  @Input()
  defaultPageSize = 10;

  @Input()
  pageSizeOptions = [10];

  count = 0;
  @Input('count')
  set countValue(value){
    this.count = value;
  }
  
  @Input('defaultCurrentPage')
  set defaultCurrentPage(value){
    this._currentPage = value;
  }

  @Input()
  position = 'center'; // flex: start / center / end

  @Input()
  maxPageLink = -1; // set to Number to limit page links

  _pages: number;

  _pageSize: number;

  _pageLinks: { page: number }[];

  _currentPage: number;

  _viewAllMode = false;

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      this.pageChanged.emit(new PageChangedEvent(this._currentPage, this._pageSize));
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'countValue') {
        if (changes[propName].firstChange) {
          this._currentPage = 1;
        }
        this._pageSize = this._pageSize || this.defaultPageSize;
        this._pages = Math.ceil(this.count / this._pageSize);
        if (this._pages < this._currentPage && !this._viewAllMode) {
          this._currentPage = this._pages || 1;
          //this.pageChanged.emit(new PageChangedEvent(this._currentPage, this._pageSize));
        }
        this.calculatePageLinks();
      } else if (propName === 'defaultPageSize') {
        if (changes[propName].firstChange) {
          this._currentPage = 1;
        }
        this._pageSize = this.defaultPageSize;
        this._pages = Math.ceil(this.count / this._pageSize);
        if (this._pages < this._currentPage) {
          this._currentPage = this._pages || 1;
          //this.pageChanged.emit(new PageChangedEvent(this._currentPage, this._pageSize));
        }
        this.calculatePageLinks();
      }
    }
  }

  changePageSize(pageSize: number) {
    this._viewAllMode = false;
    this._pageSize = pageSize;
    this._pages = Math.ceil(this.count / this._pageSize);
    this._currentPage = null;
    this.goToPage(1);
  }

  goToPage(page: number) {
    if (this._currentPage !== page) {
      this._currentPage = page;
      this.calculatePageLinks();
      this.pageChanged.emit(new PageChangedEvent(this._currentPage, this._pageSize));
    }
  }

  viewAll() {
    this._viewAllMode = true;
    this.pageChanged.emit(new PageChangedEvent(0, 0));
  }

  reset() {
    this._viewAllMode = false;
    this._currentPage = 1;
    this._pageSize = this.defaultPageSize;
    this.pageChanged.emit(new PageChangedEvent(this._currentPage, this._pageSize));
  }

  calculatePageLinks() {
    let startPage;
    let endPage;
    if (this._pages <= this.maxPageLink || this.maxPageLink === -1) {
      startPage = 1;
      endPage = this._pages;
    } else if (this._currentPage <= Math.ceil(this.maxPageLink / 2)) {
      startPage = 1;
      endPage = this.maxPageLink;
    } else if (this._currentPage + Math.ceil(this.maxPageLink / 2) > this._pages) {
      startPage = this._pages - this.maxPageLink + 1;
      endPage = this._pages;
    } else {
      startPage = this._currentPage - Math.ceil(this.maxPageLink / 2) + 1;
      endPage = startPage + this.maxPageLink - 1;
    }
    this._pageLinks = Array.from(new Array(endPage - startPage + 1), (value, index) => {
      return { page: startPage + index };
    });
  }

  getClassPosition() {
    return `justify-content-${this.position}`;
  }
}
