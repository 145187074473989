import { Component } from '@angular/core';
import { Router, ResolveStart, ResolveEnd } from '@angular/router';

@Component({
  selector: 'ds365-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  logoPath = '/assets/images/logo.svg';
  title = 'application';
  loading = false;
  theme = 'red';
  constructor(private router: Router) {
    // Show loading when resolve project for route
    this.router.events.subscribe(event => {
      if (event instanceof ResolveStart) {
        this.loading = true;
      } else if (event instanceof ResolveEnd) {
        this.loading = false;
      }
    });
  }
}
