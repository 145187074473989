<div class="card  notification-item card-default rounded-1 my-1 border-0 shadow-lg">
    <div class="card-body">
      <div class="d-flex w-100 justify-content-between">
        <h5
          class="mb-1 font-weight-bold text-truncate"
          style="max-width: 27rem"
          [placement]="'bottom'"
          [ngbTooltip]="notify.message"
        >
          {{ notify.message }}
        </h5>
        <small class="font-weight-bold">{{ notify.update_date | date }}</small>
      </div>
      <div class="card-text  text-muted">
        <p class="description description text-white  px-1 mt-2" [class.show]="showMore">{{ notify.description }}</p>
        <a (click)="showMore = !showMore" class="cursor collapsed small mt-2 ml-3 text-center ">{{
          showMore ? '- Show less' : '+ Show more'
        }}</a>
      </div>
  
      <a class="btn btn-secondary text-white float-right" (click)="markAsRead(notify.id)"
        ><i class="fa fa-check mr-1"></i>Remove</a
      >
    </div>
  </div>
  