import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { DatePickerComponent as dp, ECalendarValue } from 'ng2-date-picker'; 

@Component({
  selector: 'ds365-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  @ViewChild('dayPicker', { static: false }) dp: dp; 

  @Input() group: FormGroup;
  @Input() controlName: string;
  @Input() id: string;
  @Input() minDate;
  @Input() maxDate;
  @Input() format = 'MM/DD/YYYY';
  @Input() requiredMsg: string;
  @Input() placeholder: string;
  @Input() disableMinDate = false;
  @Input() returnValue: ECalendarValue = 1;

  @Input() minMode = 'day'; // "day"\|"month"\|"time"\|"daytime"

  dayPickerConfig: any = {
    showGoToCurrent: false,
    unSelectOnClick: false
  };
  toggleOpen = false;
  value;

  constructor() { }

  ngOnInit() {
    if (!this.disableMinDate) {
      this.minDate = moment();
    }

    this.dayPickerConfig = {
      ...this.dayPickerConfig,
      format: this.format,
      min: this.minDate,
      max: this.maxDate,
      returnedValueType: this.returnValue,
    };

    this.value = moment();
  }

  open(event) {
    if (this.group.get(this.controlName).enabled) {
      this.dp.api.open();
    }
  }

  blur() {
    if (this.group.get(this.controlName).invalid) {
      this.group.get(this.controlName).setValue(null);
    }
  }
}
