import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
const DEFAULT_OPTIONS = {
  disableInputDisplay: true,
  showResetButton: false,
  clickOutsideAllowed: false,
  icons: 'font-awesome'
}

@Component({
  selector: 'ds365-date-range-picker',
  templateUrl: './date-range-picker.component.html',
  styleUrls: ['./date-range-picker.component.scss']
})
export class DateRangePickerComponent implements OnInit {
  @ViewChild('dateRangePicker', { static: false }) dateRangePicker: any; 

  @Input() group: FormGroup;
  @Input() fromDateControl: string;
  @Input() endDateControl: string;
  @Input() id: string;
  @Input() placeholder = 'From - To';
  @Input() required = false;
  @Input() controlName;

  options = DEFAULT_OPTIONS;
  constructor() { }

  ngOnInit() {
  }

  rangeSelected(range){
    this.dateRangePicker.range = this.dateRangePicker.range.replace(' - ',' to ');
    this.group.get(this.fromDateControl).setValue(range.start.format('YYYY-MM-DDTHH:mm:ss'));
    this.group.get(this.endDateControl).setValue(range.end.format('YYYY-MM-DDTHH:mm:ss'));
  }

  reset(event){
    this.group.get(this.fromDateControl).setValue('');
    this.group.get(this.endDateControl).setValue('');
  }

  open(){
    this.dateRangePicker.toggleCalendarVisibility(true);
  }
}
