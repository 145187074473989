export const ClusterType = {
  DEFAULT: 'COMMERCIAL',
  TRIAL: 'TRIAL',
  TRAINING: 'TRAINING',
  DEMO: 'DEMO'
}

export const SUBSCRIPTION_TYPE = {
  NoContractual: 'Non-Contractual',
  Contractual: 'Contractual',
  ThreeYearsContract:'3-Year Contract',
  Trial: 'TRIAL',
  TRAINING: 'TRAINING',
}

export const CLUSTER_REQUEST_TYPE = {
  new: 'New',
  conversion: 'Trial Conversion',
  renew: 'Renewal',
}

export const SUBSCRIPTION_TYPE_TO_CLUSTER_TYPE = {
  MONTHLY_WITHOUT_CONTRACT : 'Commercial',
  MONTHLY_WITH_TRI_YEAR_CONTRACT: 'Commercial',
  MONTHLY_WITH_ANUAL_CONTRACT: 'Commercial',
  TRAIL: 'Trial',
  TRAINING: 'Training'
}
