import {
  Directive,
  OnInit,
  OnDestroy,
  Input,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[ds365Placeholder]',
})
export class PlaceholderDirective implements OnInit, OnDestroy, AfterViewInit {
  @Input() ds365Placeholder: string;
  @Input() ds365RequiredMsg: string;

  abstractControl: AbstractControl;
  hasView = false;
  controlValue$: Observable<any>;
  controlSubscription: Subscription;
  hasSubmitted: boolean;

  constructor(
    private control: NgControl,
    private el: ElementRef,
    private render: Renderer2
  ) {}

  get isDirty() {
    return (
      this.abstractControl.invalid &&
      (this.abstractControl.dirty || this.abstractControl.touched)
    );
  }

  ngOnInit() {
    this.abstractControl = this.control.control;
    this.controlSubscription = this.abstractControl.statusChanges.subscribe(
      status => {
        if ((status as string) === 'TOUCHED') {
          this.setPlaceHolders();
        }
      }
    );
  }

  ngAfterViewInit() {
    this.setPlaceHolders();
  }

  public setPlaceHolders() {
    if (this.isDirty && this.ds365RequiredMsg) {
      this.render.setAttribute(
        this.el.nativeElement,
        'placeholder',
        this.ds365RequiredMsg
      );
    } else if (this.ds365Placeholder) {
      this.render.setAttribute(
        this.el.nativeElement,
        'placeholder',
        this.ds365Placeholder
      );
    }
  }

  match(error: string) {
    if (this.abstractControl && this.abstractControl.errors) {
      if (Object.keys(this.abstractControl.errors).indexOf(error) > -1) {
        return true;
      }
    }
    return false;
  }

  ngOnDestroy() {
    this.controlSubscription.unsubscribe();
  }
}
