<div ngbDropdown container="body">
  <div ngbDropdownToggle>
    <ng-content></ng-content>
  </div>
  <div ngbDropdownMenu *ngIf="!disabled">
    <div class="cursor-pointer action-dropdown" ngbDropdownItem *ngFor="let action of actions"
      (click)="handleClick(action)">
      <ds365-icon *ngIf="action.image" class="icon" svgName="{{action.image.slice(0, -4)}}" svgSize="16" status="white"
        [svgStyle]="{ margin: '0 0 3px 0' }"></ds365-icon>
      <!-- <img [src]="action.image" *ngIf="action.image" width="16" style="margin: 0 0 3px 0;"> -->
      {{ action.label}}
    </div>
  </div>
</div>
