import { Component, Input, ElementRef, OnChanges, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { SelectionItem } from './selection-item';

/**
 * <ds365-select id="fooBar" [fg]="formGroup" [items]="countries" [control]="fromControl" [liveSearch]="true" [stickyOpt]="true"
 *  [browserDefault]="false" optString="name" optKey="id" noneSelectedText="Select your company from list">
 *   <ng-container label>Company Name</ng-container>
 *   <ng-container hints>Select Your Country</ng-container>
 * </ds365-select>
 * OR with browserDefault Select:
 * <ds365-select id="fooBar" [fg]="formGroup" [items]="countries" [control]="fromControl" [liveSearch]="false"
 *  [browserDefault]="true" optString="name" optKey="id" noneSelectedText="Select your company from list">
 *   <ng-container label>Company Name</ng-container>
 *   <ng-container hints>Select Your Country</ng-container>
 * </ds365-select>
 *
 * Note the liveSearch and browserDefault inputs. Plain only works with liveSearch off
 */
@Component({
  selector: 'ds365-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
})
export class SelectComponent implements OnChanges, OnInit {
  @ViewChild('search', { static: false }) searchElement: ElementRef;
  @Input() fg: FormGroup;
  @Input() control: FormControl;
  @Input() id: string;
  @Input() class: string;
  @Input() disabled: boolean;
  @Input() placeholder: string;
  @Input() optKey = 'id';
  @Input() optString = 'title';

  @Input() highlight = false;

  @Input() stickyOpt = false;

  @Input() items: SelectionItem[];
  @Input() multiple = false;

  @Input() noneSelectedText = 'Nothing selected';
  @Input() noneResultsText = 'No results matched ';
  @Input() multipleSeparator = ', ';
  @Input() liveSearch = false;
  @Input() liveSearchPlaceholder: string = null;
  @Input() liveSearchStyle: 'contains' | 'startsWith' = 'contains';
  @Input() maxOptions = 100;

  open = false;
  value: any;
  filteredItems: SelectionItem[];
  selection: string;
  query: FormControl;

  private clonedItems: SelectionItem[];

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.el.nativeElement.contains(event.target)) {
      if (this.open) {
        this.open = false;
      }
    }
  }

  constructor(private el: ElementRef) {}

  public ngOnInit(): void {
    this.query = new FormControl();

    this.query.valueChanges.subscribe(filter => {
      this._filterResults(filter);
    });

    this.control.valueChanges.subscribe(value => {
      if (value === null) {
        this.selection = this.noneSelectedText;
        this.query.reset();
      }
    });

    this._updateValue();
  }

  public ngOnChanges(changes: { [propertyName: string]: any }) {
    const that = this;

    if (changes.hasOwnProperty('items')) {
      let clone: SelectionItem[] = [];

      const currentValue = changes.items.currentValue;
      if (currentValue) {
        clone = currentValue.map((item: SelectionItem) => ({
          id: item[this.optKey],
          title: item[this.optString],
          disabled: item.disabled,
          selected: item.selected,
          ignoreSearch: item.ignoreSearch,
          divider: item.divider,
        }));
      }

      that.clonedItems = clone;
      this._updateSelectionText();
      this._updateValue();
      this._filterResults(null);
    }
  }

  get emptySearch(): boolean {
    return this.filteredItems === undefined || this.filteredItems.length === 0;
  }

  get isInvalid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  get isRequired(): boolean {
    const validator = this.control.validator && this.control.validator({} as AbstractControl);
    return validator && validator.required;
  }

  toggleOpen() {
    if (!this.disabled) {
      this.open = !this.open;
      if (this.open) {
        this.query.reset();
        setTimeout(() => this.searchElement.nativeElement.focus(), 0);
      } else {
        setTimeout(() => this.searchElement.nativeElement.blur(), 0);
      }
    }
  }

  toggleItem(item: SelectionItem) {
    if (!this.multiple) {
      this.clonedItems.forEach(o => {
        o.selected = false;
      });

      item.selected = !item.selected;
    } else {
      this._processMultipleSelection(item);
    }

    this._updateSelectionText();
    this._updateValue();
    this.toggleOpen();
  }

  public addValidators(): void {}

  private _updateValue() {
    const selectedItems = this.clonedItems.filter(item => item.selected).map(item => item.id);
    const values = selectedItems.join(',');
    this.control.setValue(values);
    this.value = values;
  }

  private _updateSelectionText() {
    if (this.clonedItems) {
      this.selection = this.clonedItems
        .filter(item => item.selected)
        .map(item => item.title)
        .join(this.multipleSeparator);
    }

    if (!this.selection) {
      this.selection = this.noneSelectedText;
    }
  }

  private _filterResults(filter: string) {
    const contains = this.liveSearchStyle === 'contains';

    if (!filter || filter === '') {
      this.filteredItems = this.clonedItems;
    } else {
      this.filteredItems = this.clonedItems.filter(item => {
        if (item.ignoreSearch) {
          return true;
        }
        return contains
          ? item.title.toLowerCase().includes(filter.toLowerCase())
          : item.title.toLowerCase().startsWith(filter.toLowerCase());
      });
    }
  }

  private _processMultipleSelection(item: SelectionItem) {
    const selectedItems = this.clonedItems.filter(o => o.selected);
    // only select the items if we are under the max options
    if (!item.selected && selectedItems.length >= this.maxOptions) {
      return;
    }
    item.selected = !item.selected;
  }
}
