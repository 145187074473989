import { Component, OnInit, Input } from '@angular/core';
import { ControlBase } from '../control';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'ds365-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss'],
})
export class TextAreaComponent implements ControlBase {
  @Input() group: FormGroup;
  @Input() controlName: string;

  @Input() id: string;

  @Input() placeholder: string;
  @Input() requiredMsg: string;
  @Input() maxLength: number;

  @Input() rows: number;
  @Input() isRounded: boolean;
  @Input() resize: boolean;

  constructor() {}
}
