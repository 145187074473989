import { ChangeDetectorRef, Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

import { ControlBase } from '../control';
import { RadioItem } from './radio-items';
import { FormControlName, FormGroup } from '@angular/forms';

@Component({
  selector: 'ds365-radio-group',
  template: `
    <div [formGroup]="group" [attr.id]="id">
      <div
        class="custom-control custom-radio"
        *ngFor="let item of transfomredItems; let idx = index"
        [ngbTooltip]="item.title || ''"
        [ngClass]="{ 'custom-control-inline': inline }"
      >
        <input
          type="radio"
          class="custom-control-input"
          [name]="controlName"
          [value]="item.value"
          [attr.id]="item.value"
          [formControlName]="controlName"
          [attr.disabled]="item.disabled"
          ds365IsInvalid
        />
        <label class="custom-control-label" [ngClass]="{'disabled': item.disabled}" [attr.for]="item.value" [innerHTML]="item.label"> </label>
      </div>
    </div>
  `,
  styles: [
    `label.disabled {
      color: #484848 !important;
    }`
  ]
})
export class RadioGroupComponent implements ControlBase, OnChanges {
  @Input() id;


  @Input() controlName: FormControlName;
  @Input() group: FormGroup;

  @Input() items: RadioItem[];
  @Input() optLabel = 'title';
  @Input() optValue = 'id';
  @Input() inline = false;

  public transfomredItems: RadioItem[];

  constructor(protected cd: ChangeDetectorRef) {}

  public ngOnChanges(changes: { [propertyName: string]: any }) {
    if (changes.hasOwnProperty('items')) {
      let clone: RadioItem[] = [];

      const currentValue = changes.items.currentValue;
      if (currentValue) {
        clone = currentValue.map((item: RadioItem) => ({
          label: item[this.optLabel],
          value: item[this.optValue],
          disabled: item.disabled,
          checked: item.checked,
          title: item.title,
        }));
      }
      this.transfomredItems = this.markDefaultSelection(clone);
    }
  }

  private markDefaultSelection(items: RadioItem[]): RadioItem[] {
    // if (items.length > 0 && !items.find((o) => o.checked)) {
    //   this.group.get(this.controlName).setValue()
    // }
    return items;
  }
}
