import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';

@Component({
  selector: 'ds365-typeahead',
  templateUrl: './typeahead.component.html',
  styleUrls: ['./typeahead.component.scss']
})
export class TypeaheadComponent implements OnChanges {

  @Input() data;
  @Input() inputFormat;
  @Input() resultFormat;
  @Input() iconSelect = false;
  @Input() placement: PlacementArray;
  @Input() placeholder = 'Search';
  @Input() editable = false;
  @Input() characterLength = 1;

  @Output() itemSelected = new EventEmitter();
  @Output() searchKeyword = new EventEmitter();

  _model: any;
  _searchData$ = new Subject();

  constructor() { }

  formatter = (r) => r[this.inputFormat];

  search = (text$: Observable<string>) => text$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap((term: string) => {
      if (!term || term.length < this.characterLength) {
        return of([]);
      }
      this.searchKeyword.emit(term);
      return this._searchData$;
    }
    ));

  ngOnChanges(changes) {
    if (changes.data) {
      this._searchData$.next(this.data);
    }
  }

  onModelChange($event) {
    if (this.iconSelect) {
      return;
    }
    this.selectItem();
  }

  selectItem() {
    if (this._model) {
      this.itemSelected.emit({ model: this._model });
      if (this.iconSelect) {
        this._model = null;
      }
    }
  }

  onKeydown(event) {
    if (event.key === 'Backspace') {
      if (this._model) {
        this._model = null;
      }
    } else {
      if (this._model) {
        event.preventDefault();
      }
    }
  }
}
