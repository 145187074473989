<div class="card notifications card-default  border-0 shadow-lg" style="width: 40rem;">
    <div class="card-header border-0  shadow-lg">
      <span class="h3">Notifications ({{ (notifications && notifications.length) || 0 }})</span>
      <span (click)="onClosed()" class="float-right h2" style="cursor: pointer;">
        <span aria-hidden="true">&times;</span>
      </span>
      <div class="pull-right float-right mr-4 spinner-border spinner-border-sm mt-2" *ngIf="loading" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <div class="card-body">
      <div *ngIf="notifications && !notifications.length">
        <div class="h-100 row text-center uk-vertical-align-middle  mt-4 align-items-center">
          <div class="col">
            <p class="font-weight-bold">
              You don't have any new notifications
            </p>
          </div>
        </div>
      </div>
      <ds365-notification-card (markRead)="markAsRead($event)" [notify]="notify" *ngFor="let notify of notifications">
      </ds365-notification-card>
    </div>
  </div>
  