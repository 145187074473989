import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export abstract class IReader {
  renderMode: string; // 'client' | 'server'

  /**
   * Required for Client Pagination
   * Implement your own logic for compare function here
   */
  abstract clientSideSortCompareFn(sortCriteria: {
    field: string;
    order: string;
  }): (a, b) => number;

  /**
   * Required for Client Pagination
   * Implement your own logic for search data here
   */
  abstract clientSideSearchFn(value: string): (item) => boolean;

  /**
   * Required for Client Pagination
   * Implement your own logic for filer function here
   */
  abstract clientSideFilterFn(
    filteringCriteria: { field: string; value: string }[]
  ): (item) => boolean;

  /**
   * Required for Server Pagination
   * Implement your own logic for get paged data here
   */
  abstract serverSidePageData(
    filteringCriteria: { field: string; value: string }[],
    searchCriteria: string,
    sortCriteria: { field: string; order: string },
    pageCriteria: { page: number; size: number }
  ): Observable<any[]>;
}
