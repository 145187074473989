import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbCollapseModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '../icon/icon.module';
import { NavbarsModule } from '../navbars/navbars.module';
import { HeaderComponent } from './header.component';
import { HeaderService } from './header.service';
import { CardComponent } from './notifications/card/card.component';
import { NotificationsComponent } from './notifications/notifications.component';

@NgModule({
  declarations: [HeaderComponent, NotificationsComponent, CardComponent],
  imports: [CommonModule, NavbarsModule, NgbCollapseModule, IconModule, NgbTooltipModule],
  providers: [HeaderService],
  exports: [HeaderComponent],
})
export class HeaderModule { }
