import { Component } from '@angular/core';
import { BANNER_CONTENT } from '../banner.token';
import { BannerContent } from '../banner.model';

@Component({
  selector: 'ds365-banner-wizard-nav',
  template: `
    <div class="container" *ngIf="id === 'wizard-nav'">
      <div class="row">
        <div class="col-sm-3 align-self-center">
          <h3 class="font-weight-light">
            <ng-content select="[banner-title]"></ng-content>
          </h3>
        </div>
        <div class="col-sm-6 align-self-center">
          <ng-content select="[banner-nav]"></ng-content>
        </div>
      </div>
    </div>
  `,
  providers: [
    { provide: BANNER_CONTENT, useExisting: BannerWizardNavComponent },
  ],
})
export class BannerWizardNavComponent implements BannerContent {
  public key: string;
  public id: string;
}
