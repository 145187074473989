import {
  Component,
  ComponentFactoryResolver, ComponentRef, Input, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GetStartedComponent } from '@theme/get-started/get-started.component';

@Component({
  selector: 'ds365-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupComponent implements OnInit {

  @ViewChild('container', { read: ViewContainerRef, static: true }) container: ViewContainerRef;

  @Input() componentName: string; 

  private componentRef: ComponentRef<{}>;
  private componentsMapping = {
      getStartedWizard: GetStartedComponent
  };

  @Input() popupTitle;
  @Input() popupContent;
  @Input() popupHTMLContent;
  @Input() showFooter = true;


  private _background = '';
  @Input()
  set background(value: string) {
    this._background = `bg-${value ? value : 'primary'}`;
  }

  get background(): string {
    return this._background;
  }

  constructor(public activeModal: NgbActiveModal, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    if (this.componentName){
    const component =this.componentsMapping[this.componentName];

    const factory = this.componentFactoryResolver.resolveComponentFactory(component);
    this.componentRef = this.container.createComponent(factory);
    }
  }

  public getStyleClasses() {
    return {
      [this._background]: true
    };
  }
}
