import { Injectable, ComponentRef, Injector, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IReader } from '@core/interfaces/reader.interface';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DS365Overlay } from '@theme/layouts';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { OverlayRef } from '@angular/cdk/overlay';
import { VideoPlayerComponent } from '@theme/layouts/video-player/video-player.component';
import { VIDEO_PLAYER_DATA } from '@theme/layouts/video-player/video-player.token';
import { UserService } from '@core/services/user.service';

@Injectable()
export class VideoLibraryService extends IReader {
  renderMode = 'client';
  overlayRef: OverlayRef;

  constructor(private http: HttpClient, private ds365Overlay: DS365Overlay, private injector: Injector, private userService:UserService) {
    super();
  }

  clientSideSortCompareFn(sortCriteria: { field: string; order: string }): (a: any, b: any) => number {
    return (a, b) => {
      if (sortCriteria.field === 'dateCreated' || sortCriteria.field === "order") {
        if (sortCriteria.order === 'asc') {
          return (new Date(a[sortCriteria.field]) as any) - (new Date(b[sortCriteria.field]) as any);
        } else {
          return (new Date(b[sortCriteria.field]) as any) - (new Date(a[sortCriteria.field]) as any);
        }
      }
      if (sortCriteria.order === 'asc') {
        return a[sortCriteria.field].localeCompare(b[sortCriteria.field]);
      } else {
        return b[sortCriteria.field].localeCompare(a[sortCriteria.field]);
      }
    };
  }

  clientSideSearchFn(value: string): (item: any) => boolean {
    return (item: any) => {
      return (
        Object.values(item)
          .join('|')
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
      );
    };
  }

  clientSideFilterFn(filteringCriteria: { field: string; value: string }[]): (item: any) => boolean {
    return item => {
      const value: string = item[filteringCriteria[0].field];
      return value.toLowerCase() === filteringCriteria[0].value.toLowerCase();
    };
  }
  serverSidePageData(
    filteringCriteria: { field: string; value: string }[],
    searchCriteria: string,
    sortCriteria: { field: string; order: string },
    pageCriteria: { page: number; size: number }
  ): Observable<any[]> {
    throw new Error('Method not implemented.');
  }

  _createInjector(video) {
    const injectionTokens = new WeakMap();
    injectionTokens.set(OverlayRef, this.overlayRef);
    injectionTokens.set(VIDEO_PLAYER_DATA, video);
    return new PortalInjector(this.injector, injectionTokens);
  }

  getVideos() {
    return this.userService.getConfigurations('videoLibrary', '').pipe(
      map((values: any[]) => {
        return values.map((v: any) => {
          const value = JSON.parse(v.value);
          return { key: v.key, ...value };
        });
      })
    );
  }

  play(video) {
    this.overlayRef = this.ds365Overlay.createWithDefaultConfig(document.getElementById('kvp-container'));
    const injector = this._createInjector(video);
    const containerRef: ComponentRef<VideoPlayerComponent> = this.overlayRef.attach(
      new ComponentPortal(VideoPlayerComponent, null, injector)
    );
    return containerRef;
  }

  stop() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
    }
  }
}
