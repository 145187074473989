<ng-template #rt let-r="result" let-t="term">
    <div>{{ r[resultFormat] }}</div>
  </ng-template>

  <div class="d-flex flex-fill align-items-center">
    <div class="position-relative flex-fill">
      <span class="input-icon">
        <ds365-icon svgName="search" svgSize="18"></ds365-icon>
      </span>
      <input
        style="padding-left: 40px"
        id="typeahead-template"
        type="text"
        class="form-control"
        [placement]="placement"
        [placeholder]="placeholder"
        [(ngModel)]="_model"
        [ngbTypeahead]="search"
        [resultTemplate]="rt"
        [inputFormatter]="formatter"
        [editable]="editable"
        (keydown)="onKeydown($event)"
        (ngModelChange)="onModelChange($event)"
      />
    </div>
    <div class="ml-2" *ngIf="iconSelect">
      <button class="btn btn-primary text-white" (click)="selectItem()" [disabled]="!_model">
        <ds365-icon
          class="icon"
          svgName="add-row"
          svgSize="24"
          status="white"
          [svgStyle]="{ margin: '0 0 2px 0' }"
        ></ds365-icon>
        Add
      </button>
    </div>
  </div>
