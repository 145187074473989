<div class="form-group position-relative" [formGroup]="group">
    <label [attr.for]="id">
        <ng-content select="[label]"></ng-content>
        <span class="text-danger" *ngIf="requiredMsg"> *</span>
    </label>
    <dp-date-picker
        #dayPicker
        [config]=dayPickerConfig
        [attr.id]="id"
        [formControlName]="controlName"
        [theme]="'dp-material'"
        [mode]="minMode"
    >
    </dp-date-picker>
    <i class="fa fa-calendar" (click)="open($event)" aria-hidden="true"></i>
</div>