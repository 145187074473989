import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ds365-action-dropdown',
  templateUrl: './action-dropdown.component.html',
  styleUrls: ['./action-dropdown.component.scss'],
})
export class ActionDropdownComponent implements OnInit {
  @Input()
  actions = [];

  @Output()
  actionSelected: EventEmitter<any> = new EventEmitter();

  @Input()
  disabled;

  constructor() {}

  ngOnInit() {}

  handleClick(action) {
    this.actionSelected.emit(action);
  }
}
