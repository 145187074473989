<div class="ds365-alert w-100" *ngIf="alert" [ngClass]="cssClass(alert)">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-1 align-self-center text-center">
        <div *ngIf="icon">
          <ds365-icon [svgName]="icon" [svgSize]="iconSize"></ds365-icon>
        </div>
      </div>
      <div class="col-md-10 align-self-center">
        <ng-content></ng-content>
      </div>
      <div class="col-md-1 align-self-center text-center">
        <div *ngIf="!sticky">
          <a class="cursor-pointer" (click)="close()">
            <ds365-icon svgName="close" status="white" svgSize="25"></ds365-icon>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
