import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  public constructor() {}

  get(key) {
    return JSON.parse(localStorage.getItem(key)) || null;
  }

  set(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}
