<div class="sidebar" [ngClass]="{ 'sidebar-width': !collapsed, 'border-right': true }">
  <ul class="nav flex-column" *ngIf="sideBarData.appLinks?.length > 0">
    <li class="border-bottom">
      <div class="d-flex flex-row" (click)="collapsed = !collapsed">
        <ds365-icon class="mt-2" [svgName]="collapsed ? 'expand' : 'collapse'" svgSize="20" status="white"></ds365-icon>
        <span *ngIf="!collapsed" class="nav-link text-white">Collapse</span>
      </div>
    </li>
    <li *ngFor="let sideBarItem of sideBarData.appLinks; let last = last"  [ngClass]="{ 'border-bottom pb-3': last }">
      <div class="zoom d-flex flex-row" (click)="navigate(sideBarItem)">
        <ds365-icon class="mt-2" svgName="{{ sideBarItem.icon }}" svgSize="20" status="white"></ds365-icon>
        <span *ngIf="!collapsed" class="nav-link zoom text-white" [id]="sideBarItem.id ? sideBarItem.id : ''">{{
          sideBarItem.title
        }}</span>
      </div>
    </li>
  </ul>
  <ul class="nav flex-column pt-3" *ngIf="sideBarData.externalLinks?.length > 0">
    <li [ngClass]="{ 'link-disabled': sideBarItem.disabled }" *ngFor="let sideBarItem of sideBarData.externalLinks">
      <div class=" d-flex flex-row" [ngClass]="{ zoom: !sideBarItem.disabled }" (click)="openExternalLink(sideBarItem)">
        <ds365-icon class="mt-2" svgName="{{ sideBarItem.icon }}" svgSize="20" status="white"></ds365-icon>
        <span *ngIf="!collapsed" class="nav-link zoom text-white" [id]="sideBarItem.id ? sideBarItem.id : ''">{{
          sideBarItem.title
        }}</span>
      </div>
    </li>
  </ul>
</div>
<div class="sidebar-placeholder" [ngClass]="{ 'sidebar-width': !collapsed, 'border-right': true }"></div>
