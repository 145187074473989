import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavbarItem } from '../navbars.model';
import { NgbDropdown, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '@core/auth';

@Component({
  selector: 'ds365-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  providers: [NgbDropdown],
})
export class DropdownComponent {
  @ViewChild('tooltip', { static: false }) public tooltipTrigger: NgbTooltip;
  @Input()
  item: NavbarItem;
  public childNav: any;
  public allRoles: any;

  @Input()
  status: 'white' = 'white';

  @Input()
  caret = true;
  roles: any;
  allChildRoles = false;
  hasSubmenu: boolean;
  thridLevelChild: any;
  innerChildShow: boolean;

  constructor(private authService: AuthService) {}

  ngOnInit() {
    this.showLinkRoleWise();
  }

  showLinkRoleWise() {
    this.getRoles();
    Object.keys(this.roles).forEach(key => {
      this.roles = this.roles[key];
    });
    if (this.item.roles) {
      if (this.item.roles.length === 0 || this.item.roles.length === 'undefined') {
        this.allRoles = false;
      } else if (this.item.roles.includes('*')) {
        this.allRoles = true;
      } else if (this.item.roles.some(r => this.roles.indexOf(r) >= 0)) {
        this.allRoles = true;
      }
    } else if (!this.item.roles) {
      this.allRoles = true;
      this.allChildRoles = true;
    } else {
      this.allRoles = false;
    }

    if (this.item.children) {
      this.childNav = this.item.children;
      for (const child of this.childNav) {
        if (child.roles) {
          this.allChildRoles = false;
          for (const userRoles of this.roles) {
            if (child.roles.indexOf(userRoles) > -1 || child.roles.includes('*')) {
              child.visibility = true;
            }
          }
        } else if (!child.roles) {
          this.allChildRoles = true;
        } else {
          this.allChildRoles = false;
        }
      }
    }

    if (this.childNav) {
      for (const navChild of this.childNav) {
        if (navChild.children) {
          this.thridLevelChild = navChild.children;
          for (const innerChild of this.thridLevelChild) {
            if (innerChild.roles) {
              for (const userRoles of this.roles) {
                if (innerChild.roles.indexOf(userRoles) > -1) {
                  innerChild.visibility = true;
                }
              }
              if (innerChild.roles.includes('*')) {
                this.innerChildShow = true;
              } else if (innerChild.roles.length === 0 || innerChild.roles.length === 'undefined') {
                this.innerChildShow = false;
              }
            } else if (!innerChild.roles) {
              this.innerChildShow = true;
            } else {
              this.innerChildShow = false;
            }
          }
        }
      }
    }
  }

  getRoles() {
    this.authService.getToken().subscribe((authData: any) => {
      this.roles = authData.getProp('realm_access');
    });
  }

  triggerTooltip(){
    if (this.tooltipTrigger && this.item.highlight){
      this.tooltipTrigger.ngbTooltip = this.item.tooltip;
      this.tooltipTrigger.open()
    } else {
      return '';
    }
  }
}
