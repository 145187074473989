import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core'; 
import { DomSanitizer } from '@angular/platform-browser'; 

@Component({
  selector: 'ds365-video-tile',
  templateUrl: './video-tile.component.html',
  styleUrls: ['./video-tile.component.scss'],
})
export class VideoTileComponent implements OnInit {
  videoImage;
  @Input() video: any = {};

  @Input() videoOnly = false;
  @Input() width = '431px';

  @Output() playVideo: EventEmitter<any> = new EventEmitter();

  constructor( private sanitzer:DomSanitizer ) {};

  ngOnInit() {
    const trustedUrl = `url(${this.video.image})`;
     this.videoImage = trustedUrl;
  }
 
}
