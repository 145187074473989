import { Component, OnInit, ContentChildren, ContentChild, AfterContentInit } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';
import { StepComponent } from './step/step.component';

@Component({
  selector: 'ds365-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }]
})
export class StepperComponent extends CdkStepper {

  next(): void {
    const step = this.selected as StepComponent;
    let promise;

    if (typeof(step.canExit) === typeof(true)) {
      promise = Promise.resolve(step.canExit as boolean);
    } else if (step.canExit instanceof Function) {  
      promise = Promise.resolve(step.canExit())
    }

    if (promise) {
      promise.then(value => {
        if (value) {
          this.selectedIndex = Math.min(this.selectedIndex + 1, this.steps.length - 1);
        }
      });
    }
  }

}
