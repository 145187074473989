import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UserService } from '@core/services/user.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StepperComponent } from '@theme/controls/stepper/stepper.component';
import { VideoLibraryService } from 'app/modules/video-library/video-library.service';
import { Subject } from 'rxjs';
import { NAVIGATION } from '../../modules/welcome/resources/welcome.resources';

@Component({
  selector: 'ds365-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss'],
  providers: [VideoLibraryService]
})
export class GetStartedComponent implements OnInit {
  @ViewChild(StepperComponent, { static: false }) stepper: StepperComponent;
  public notification: Subject<{ message: string; type: string }> = new Subject();

  public form: FormGroup;

  public navigations = NAVIGATION;
  public loading = false;
  public alertText: string;
  public videosMap;

  constructor(
    private videoLibraryService: VideoLibraryService,
    public cdr: ChangeDetectorRef,
    private userService: UserService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.updateNav(['next', 'cancel']);

    this.loading = true;
    this.videoLibraryService.getVideos().subscribe((response: any[]) => {
      const arr = response.filter(v => v.key === 'try-before-you-buy' || v.key === 'how-to-purchase-subscription');
      this.videosMap = new Map(arr.map(i => [i.key, i]));
      this.loading = false;
      const dict = {};
    });
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  onPlay(video) {
    this.videoLibraryService.play(video);
  }
  updateNav(enables) {
    window.scroll(0, 0);
    this.loading = false;
    for (const prop in this.navigations) {
      if (this.navigations.hasOwnProperty(prop)) {
        this.navigations[prop] = enables.indexOf(prop) > -1;
      }
    }
  }

  iconStatus(index): string {
    if (!this.stepper) {
      return 'white';
    }
    if (index <= this.stepper.selectedIndex) {
      return 'white';
    } else {
      return 'dark';
    }
  }

  iconBg(index): string {
    if (!this.stepper) {
      return 'dark';
    }

    if (index === this.stepper.selectedIndex) {
      return 'dark';
    } else if (index < this.stepper.selectedIndex) {
      return 'success';
    } else {
      return 'white';
    }
  }

  public onStepChanged(event) {
    switch (event.selectedIndex) {
      case 0:
        this.updateNav(['next']);
        break;
      case 1:
        this.updateNav(['next', 'back']);
        break;
      case 2:
        this.updateNav(['back']);
        break;
    }
  }

  private notify(msg: string, type = 'success') {
    this.notification.next({ message: msg, type });
  }
}
