import {
  Directive,
  OnInit,
  HostBinding,
  Optional,
  Input,
  IterableDiffers,
} from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
/**
 * Use with FormControl as:
 * <input oecIsInvalid [formControl]="formcontrol" />
 *
 * OR any other HTML element by providing the control
 * <button type="button" [oecIsInvalid]="formcontrol"></button>
 */
@Directive({
  selector: '[ds365IsInvalid]',
})
export class IsInvalidDirective implements OnInit {
  @Input()
  ds365IsInvalid: AbstractControl;

  @HostBinding('class.is-invalid')
  get invalid() {
    return (
      this.abstractControl.invalid &&
      (this.abstractControl.dirty || this.abstractControl.touched)
    );
  }

  private abstractControl: AbstractControl;

  constructor(@Optional() private control: NgControl) {}

  ngOnInit() {    
    if (this.ds365IsInvalid) {
      this.abstractControl = this.ds365IsInvalid;
    } else if (this.control.hasOwnProperty('control')) {
      this.abstractControl = this.control.control;
    }
  }
}
