import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import { TokenService } from './token.service';
import { AuthToken } from '../models/token';

@Injectable()
export class AccessCheckService {

  constructor(protected tokenService: TokenService) {
  }

  isGranted() {

  }

}
