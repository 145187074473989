import { Component, OnInit, Input } from '@angular/core';
import { NavbarItem } from '../../navbars.model';
import { CommunicationService } from '@core/services/communication.service';

@Component({
  selector: 'ds365-command-link-item',
  template: `
  <span class="nopadding" ngbDropdownItem [disabled]="disabledItem">
    <a
      id="{{ id }}"
      (click)="do(item.call)"
      class="nav-link pt-0 pb-0 text-white mt-10 mb-10 text-nowrap"
    >
      <ng-content></ng-content>
    </a>
    </span>
  `,
  styleUrls: [],
})
export class CommandLinkItemComponent implements OnInit {
  constructor(private communicationService: CommunicationService){}
  @Input()
  public id = '';

  @Input()
  public item: NavbarItem;

  @Input()
  disabledItem = false;

  ngOnInit() {}

  do(action: string) {
    this.communicationService.emitChange(action);
  }
}
