<div class="form-group position-relative" [formGroup]="group">
  <label [attr.for]="id">
    <ng-content select="[label]"></ng-content>
    <span class="text-danger"> *</span>
  </label>
  <div class="note position-absolute"><ng-content select="[note]"></ng-content></div>
  <div ngbDropdown autoClose="outside" #d="ngbDropdown" container="body" (openChange)="toggled($event)" [class.disabled]="disabled"
    [attr.aria-disabled]="disabled">
    <button
      [attr.id]="id"
      [ngClass]="classes"
      type="button"
      class="btn dropdown-toggle custom-select"
      ngbDropdownToggle
      [ds365IsInvalid]="control"
      [class.disabled]="disabled"
      [attr.aria-disabled]="disabled"
      [disabled]="disabled"
    >
      <div class="selected-item">{{ isInvalid ? placeholder : selection }}</div>
      <ng-container *ngIf="isInvalid; else invalidIcon"></ng-container>
      <ng-template #invalidIcon>
        <!-- <ds365-icon svgName="arrow-down" svgSize="18" status="secondary" class="pull-right float-right"></ds365-icon> -->
      </ng-template>
    </button>
    <form [formGroup]="tagForm">
      <div ngbDropdownMenu class="pl-15 font-weight-light" style="overflow: hidden;">
        <div class="multi-searchbox" *ngIf="liveSearch">
          <input
            #search
            class="form-control"
            [formControl]="query"
            type="text"
            autocomplete="off"
            role="textbox"
            aria-label="Search"
            placeholder="{{ liveSearchPlaceholder }}"
          />
        </div>
        <div style="overflow: auto; text-overflow: ellipsis; max-height: 270px; margin-top: 10px;">
          <div
          ngbDropdownItem
          formArrayName="tags"
          *ngFor="let tag of tagForm['controls'].tags['controls']; let i = index"
          class="custom-control custom-checkbox"
          [hidden]="!items[i].filtered"
        >
          <input
            class="custom-control-input cursor-pointer"
            type="checkbox"
            [formControlName]="i"
            [attr.id]="'id_' + items[i].id"
            [attr.name]="items[i].name"
          />
          <label class="custom-control-label cursor-pointer" [attr.for]="'id_' + items[i].id">
            {{ items[i].name }}
          </label>
        </div>
        </div>
      </div>
    </form>
  </div>
  <small class="form-text text-muted"
    >&nbsp;
    <ng-content select="[hints]"></ng-content>
  </small>
</div>
