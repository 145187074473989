<div class="ds365-chart">
  <canvas baseChart width="100%" height="100%"
    [datasets]="chartData"
    [labels]="chartLabels"
    [options]="chartOptions"
    [colors]="chartColors"
    [legend]="chartLegend"
    [chartType]="chartType"
    [plugins]="chartPlugins">
  </canvas>
</div>