import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ChartComponent } from './chart.component';
import { FormsModule } from '@angular/forms';
import { ChartsModule,ThemeService } from 'ng2-charts';
@NgModule({
  declarations: [ChartComponent],
  imports: [CommonModule, NgbModule,FormsModule,ChartsModule],
  exports: [ChartComponent],
  providers:[ThemeService]
})
export class ChartModule {}
