import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { isEmpty } from 'lodash';
import { ClusterService } from '../cluster.service';

@Injectable()
export class MemberClusterRouteGuard {
  constructor(
    private clusterService: ClusterService,
    private router: Router,
  ) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    const from = route.queryParams.login ? true : false;
    return this.clusterService
      .getMemberCluster()
      .toPromise()
      .then((response: any) => {
        if (isEmpty(response) || !from) {
          return true;
        }
        this.router.navigate(['cluster', response.cluster_id]);
        return false;
      })
      .catch(err => {
        return true;
      });
  }
}
