import { Component, Input, ElementRef } from '@angular/core';
import { FormGroup, FormControlName } from '@angular/forms';

@Component({
  selector: 'ds365-checkbox',
  templateUrl: 'checkbox.component.html',
})
export class CheckboxComponent {
  @Input() id: string;

  @Input() group: FormGroup;
  @Input() controlName: FormControlName;

  @Input() inline: boolean;
  @Input() value: any;

  constructor(protected el: ElementRef) {}
}
