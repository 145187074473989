/**
 * This Service is used for general communication among components. Any component can pass the
 * target component name and message content to the receiver component.
 */

import { Injectable } from '@angular/core';
import { MessageType } from '../models/message.service';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class MessageService {
  private message = new Subject<any>();

  sendMessage(message: MessageType) {
    this.message.next(message);
  }

  subscribeMessage(): Observable<any> {
    return this.message.asObservable();
  }

  clearMessage() {
    this.message.next();
  }
}
