import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, pluck } from 'rxjs/operators';
import { Cluster } from './models/cluster.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from '@core/services/user.service';

@Injectable({
  providedIn: 'root',
})
export class ClusterService {
  public triggerChange: BehaviorSubject<any> = new BehaviorSubject({});
  constructor(private http: HttpClient, private userService:UserService) { }

  public fetchModel(data: any): Cluster {
    const cluster = new Cluster(data);
    return cluster;
  }

  public clusterNameExists(cluster: string) {
    return this.http.post(`/clusters/exists`, {
      cluster: cluster.trim(),
    });
  }

  public createCluster(data: any) {
    return this.http.post(`/clusters/requests`, data);
  }

  /** Eligible users for cluster membership */
  public users(id): Observable<any[]> {
    return this.http.get<any[]>(`/clusters/${id}/users`);
  }

  public joinClusterRequest(data: any) {
    return this.http.post(`/clusters/requests/join`, data);
  }

  public getName() {
    return this.http.get('/clusters/name');
  }

  public getRegions() {
    return this.http.get('/clusters/regions');
  }

  public getPackages(region?: string) {
    return this.http.get(`/clusters/products?region=${region}`);
  }

  public getPackageBundle(packageID: number, region: string) {
    return this.http.get(`/clusters/packages/${packageID}/regions/${region}`);
  }

  public getClusters() {
    return this.http.get('/clusters/');
  }
  getClustersByStatus(status) {
    return this.http.get(`/clusters/?status=${status}`)
  }
  public getMyClusters(userId: number) {
    return this.http.get(`clusters/user/${userId}`);
  }

  public getClusterSideBar() {
    return this.http.get('assets/data/cluster-side-bar.json');
  }

  public removeCluster(clusterID: number) {
    return this.http.delete('/clusters/bpm/' + clusterID).pipe(map(results => true));
  }

  public getClusterById(clusterId: number | string) {
    return this.http.get(`/clusters/${clusterId}`);
  }

  public getProductsByCluster(clusterId: number, subscriptionStatus?) {
    if (subscriptionStatus){
      return this.http.get(`/clusters/${clusterId}/products?subscription_status=${subscriptionStatus}`);
    } else {
      return this.http.get(`/clusters/${clusterId}/products`);
    }
  }

  public getMembersByCluster(clusterId: number) {
    return this.http.get(`/clusters/${clusterId}/members`);
  }

  public getClusterSubscriptions(clusterId: number) {
    return this.http.get(`/clusters/${clusterId}/subscription`);
  }

  public getClusterSubscriptionDetail(clusterId: number) {
    return this.http.get(`/clusters/${clusterId}/subscription/detail`);
  }

  public updateMembership(clusterId: number, userId: number, payload) {
    return this.http.put(`/clusters/${clusterId}/members/${userId}`, payload);
  }

  public removeMembership(clusterId: number, userId: number, packageId:string = '') {
    return this.http.delete(`/clusters/bpm/${clusterId}/package/${packageId?packageId:''}/members/${userId}`);
  }

  public removeSubscriptionMembersById(subscriptionDetailId: number, userId: number, clusterId:number=0): Observable<any> {
    return this.http.delete(`/subscriptions/${subscriptionDetailId}/members/${userId}?cluster_id=${clusterId}`);
  }

  public approveJoinRequest(joinId, data: any) {
    return this.http.put(`/clusters/requests/join/${joinId}/bpm/`, data);
  }

  public addMembership(clusterId: number, payload) {
    return this.http.post(`/clusters/${clusterId}/members`, payload);
  }

  public removeMemberFromCluster(clusterId: number, userId: number) {
    return this.http.delete(`/clusters/${clusterId}/members/${userId}`);
  }

  public assignProductBPM(clusterId: number, payload) {
    return this.http.post(`/clusters/bpm/${clusterId}/members`, payload);
  }

  public addSubscriptionMembersById(subscriptionDetailId: number, payload: any): Observable<any> {
    return this.http.post(`/subscriptions/${subscriptionDetailId}/members`, payload);
  } 

  public beginTrial(packageId): Observable<any> {
    return this.http.post(`/try/bpm/${packageId}`, {})
  }

  public getMemberCluster() {
    return this.http.get(`/clusters/member/cluster`, {});
  }
  public getMemberClusterByUserId(userId) {
    return this.http.get(`/clusters/member/cluster/${userId}`, {});
  }

  public getMemberWorkspace(cluster_id, user_id) {
    return this.http.get(`/clusters/${cluster_id}/members/${user_id}/workspaces`);
  }

  public restartWorkspace(id: number | string) {
    return this.http.put(`/clusters/${id}/restart`, null);
  }

  public getTrialPackageInfo(clusterPackageId) {
    return this.http.get(`/try/package/${clusterPackageId}`, {});
  }

  public getUserJoinRequests() {
    return this.http.get('/clusters/requests/user', {});
  }

  public getTrialCluster(companyId) {
    return this.http.get(`/try/companies/${companyId}/cluster`, {});
  }

  public isClusterTrial(clusterId) {
    return this.http.get(`/clusters/${clusterId}/trial`);
  }

  public getTrainingParticipants(clusterId) {
    return this.http.get(`/clusters/${clusterId}/training`);
  }

  public getCompanies() {
    return this.http.get(`/companies/`);
  }
  public getGroups() {
    return this.http.get(`/users/groups`);
  }

  public getCompaniesForTraining() {
    return this.http.get(`/companies/training`);
  }

  public packageRegions(packageId) {
    return this.http.get(`/clusters/packages/${packageId}/regions`);
  }

  public regionPref(region) {
    return this.http.get(`/region/halregion/${region}/pref`);
  }

  public getUserPackageById(cluster_id, cluster_package_user_id) {
    return this.http.get(`/clusters/${cluster_id}/packages/users/${cluster_package_user_id}`);
  }

  public getSubscriptionDetails(companyId, subId){
    return this.http.get(`/subscriptions/?companyId=${companyId}&subscriptionId=${subId}&inactive=true`).pipe(
      map(res => res[0]),
      pluck('COMPANY_SUBSCRIPTION_DETAIL')
    );
  }

  getSubConfig() {
    return this.userService.getConfigurations('subscription', 'default');
  }

  getSubAlertConfig() {
    return this.userService.getConfigurations('schedulerService', 'subscription-alert');
  }

  getManagersByCompanyId(companyId) {
    return this.http.get(`companies/${companyId}/managers`);
  }
  
  getCompany(id) {
    return this.http.get(`/companies/${id}`);
  }
  
  getHalRegion(halRegionCode) {
    return this.http.get(`region/halregion/${halRegionCode}`);
  }

  getDependantPackage(){
    return this.http.get(`/subscriptions/package/mapping`);
  }
}