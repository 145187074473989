import { Observable, BehaviorSubject, Subject } from 'rxjs';

export interface ConfirmBoxContent {
  text: string;
  isInput?: boolean;
  placeholder?: string;
  helpText?: string;
  acceptText?: string;
  rejectText?: string;
  dataToDisplay?: any[];
  class? : any
  action: Observable<boolean>;
}

export const defaultConfirmBoxContent = {
  text: 'Are you Sure?',
  acceptText: 'Yes',
  rejectText: 'No',
  action: new Subject(),
};
