import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BadgeComponent } from './badge.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
  declarations: [BadgeComponent],
  imports: [CommonModule, IconModule, NgbModule],
  exports: [BadgeComponent],
})
export class BadgeModule {}
