import { Component, OnInit, Input } from '@angular/core';
import { NavbarItem } from '../../navbars.model';

@Component({
  selector: 'ds365-outside-link-item',
  template: `
    <a
      id="{{ id }}"
      ngbDropdownItem
      [href]="item.call"
      [target]="item.target || '_self'"
      class="nav-link text-white nopadding mt-10 mb-10 text-nowrap"
    >
      <ng-content></ng-content>
    </a>
  `,
  styleUrls: [],
})
export class OutsideLinkItemComponent implements OnInit {
  @Input()
  public id = '';

  @Input()
  public item: NavbarItem;

  constructor() {}

  ngOnInit() {}
}
