import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '@core/auth';
import { UserService } from '@core/services/user.service';
import { forkJoin } from 'rxjs';

@Injectable()
export class ProfileCheckGuard implements CanActivate {
  constructor(private router: Router, private userService: UserService, private authService: AuthService) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return forkJoin([this.userService
      .termsAccepted(), this.authService.isAuthenticated()])
      .toPromise()
      .then((response: any) => {
        return this.isUserProfileExists(response);
      });
  }
  isUserProfileExists(data) {
    if (data && data.status === 401) {
      let url = 'https://decisionspace365.io?message=profile_not_found';
      if (window.location.href.indexOf("devint") > -1) {
        url = `https://decisionspace365.io?message=profile_not_found&env=devint`;
      } else if (window.location.href.indexOf("dev") > -1 || window.location.href.indexOf("10.") > -1
        || window.location.href.indexOf("localhost") > -1) {
        url = `https://decisionspace365.io?message=profile_not_found&env=dev`;
      }
      window.location.href = url;
    }
    return true;
  }
}
