import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, ContentChild, TemplateRef } from '@angular/core';
import { CdkStep } from '@angular/cdk/stepper';
import { StepIndicatorDirective } from '../stepper-navigation-bar/step-indicator.directive';

@Component({
  selector: 'ds365-step',
  templateUrl: './step.component.html',
  providers: [
    {provide: CdkStep, useExisting: StepComponent},
  ],
  encapsulation: ViewEncapsulation.None,
  exportAs: 'ds365Step',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepComponent extends CdkStep {

  @Input() canExit: ( () => boolean | Promise<boolean> ) | boolean = true;

  @Input() indicator = '';

  @ContentChild(StepIndicatorDirective, { static: true }) stepIndicatorTemplate: StepIndicatorDirective;

}