<div class="nav-dropdown" ngbDropdown [ngClass]="{
  'border-white-3 pr-2 pl-2': item.highlight
}"
[ngbTooltip]="triggerTooltip()"
#tooltip="ngbTooltip"
triggers="manual"
>
  <ng-container *ngIf="allRoles">
    <ds365-dropdown-item-template
      ngbDropdownToggle
      [item]="item"
      [disabledItem]="item?.visibility === 'disabled'"
      [disableTooltip]="!(item?.visibility === 'disabled')"
      [tooltip]="item?.tooltip"
      [noPaddingAppRoute]="false"
    >
      <span class="d-inline-block mr-20" *ngIf="item.icon">
        <ds365-icon svgName="{{ item.icon }}" svgSize="20" [status]="status"></ds365-icon>
      </span>
      <ng-content select="[label]"></ng-content>
      <span *ngIf="item.children && caret" class="ml-10">
        <ds365-icon svgName="caret" svgSize="12" [status]="status"></ds365-icon>
      </span>
    </ds365-dropdown-item-template>
  </ng-container>
  <ng-container *ngIf="item.children">
    <div ngbDropdownMenu class="nopadding pl-10 pr-10"> 
      <ng-container *ngFor="let child of item.children">
        <ng-container *ngIf="allChildRoles || child.visibility">
          <ds365-dropdown-item-template
            [item]="child"
            [disabledItem]="child?.visibility === 'disabled'"
            [disableTooltip]="!(child?.visibility === 'disabled')"
            [tooltip]="child?.tooltip"
          >
            <span class="mr-10">
              <ds365-icon svgName="{{ child.icon }}" svgSize="20" [status]="status"></ds365-icon>
            </span>
            <span class="f-w-400 align-middle">{{ child.title }}</span>
          </ds365-dropdown-item-template>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
