import { Component, OnInit, Input, HostListener } from '@angular/core';
import { NavbarItem } from '../../navbars.model';

@Component({
  selector: 'ds365-dropdown-item-template',
  template: `
    <ng-container [ngSwitch]="item.type">
      <ds365-inside-link-item
        *ngSwitchCase="'app-route'"
        [id]="id"
        [item]="item"
        [ngbTooltip]="tooltip"
        [noPadding]="noPaddingAppRoute"
        [disabledItem]="disabledItem"
        [disableTooltip]="disableTooltip"
      >
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </ds365-inside-link-item>
      <ds365-outside-link-item *ngSwitchCase="'link'" [id]="id" [item]="item">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </ds365-outside-link-item>
      <ds365-command-link-item *ngSwitchCase="'command'" [id]="id" [item]="item">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </ds365-command-link-item>
      <a *ngSwitchDefault [id]="id" class="nav-link mt-10 mb-10 text-white py-0">
        <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
      </a>
    </ng-container>
    <ng-template #contentTpl><ng-content></ng-content></ng-template>
  `,
})
export class DropdownItemTemplateComponent implements OnInit {
  private _item: NavbarItem;
  id: string;
  @Input()
  noPaddingAppRoute = true;

  @Input()
  disabledItem = false;

  @Input()
  disableTooltip = true;

  @Input()
  tooltip = '';

  constructor() {}

  ngOnInit() {}

  @Input()
  set item(item: NavbarItem) {
    if (item) {
      this.id = this.generateId(item);
    }
    this._item = item;
  }

  get item(): NavbarItem {
    return this._item;
  }

  generateId(item: NavbarItem) {
    return `ds365-nav-item_${item.title.replace(/ /g, '_')}`;
  }
}
