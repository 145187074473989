import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Alert, AlertType } from './alert.model';
import { AlertService } from './alert.service';
import { Subscription, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'ds365-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input() icon: string;
  @Input() iconSize = 50;
  @Input() sticky = true;
  @Input() autoHide: number;
  @Output() closed = new EventEmitter<any>();
  alert: Alert;
  subscription: Subscription;

  constructor(private alertService: AlertService) {}

  ngOnInit() {
    this.subscription = this.alertService.onAlert().subscribe((alert: Alert | boolean) => {
      if (!alert) {
        this.alert = null;
      } else {
        this.alert = alert as Alert;
        this.autoClose();
      }
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  cssClass(alert: Alert) {
    if (!alert) {
      return;
    }

    switch (alert.type) {
      case AlertType.Success:
        return 'bg-success';
      case AlertType.Error:
        return 'bg-danger';
      case AlertType.Info:
        return 'bg-info';
      case AlertType.Warning:
        return 'bg-warning';
    }
  }
  autoClose() {
    if (this.autoHide) {
      of(true)
        .pipe(delay(this.autoHide))
        .subscribe(_ => {
          this.close(true);
        });
    }
  }
  close(auto = false) {
    this.alert = null;
    this.closed.emit(auto);
  }
}
