import { Component, ViewEncapsulation } from '@angular/core';
import { StepperComponent } from '../stepper.component';

@Component({
  selector: 'ds365-stepper-navigation-bar',
  templateUrl: './stepper-navigation-bar.component.html',
  styleUrls: ['./stepper-navigation-bar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperNavigationBarComponent {

  constructor(private _stepper: StepperComponent) { }

  get steps(): Array<any> {
    return this._stepper.steps.toArray();
  }

}
