import { Inject, Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';
import { AuthToken } from '../models/token';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService
      .getToken()
      .pipe(
        switchMap((token: AuthToken) => {
          if (token && token.getValue()) {
            req = req.clone({
              setHeaders: {
                Authorization: 'Bearer ' + token.getValue(),
              },
            });
          }
          if (!req.headers.has('Content-Type') && !(req.body instanceof FormData)) {
            req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
          }
          req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
          return next.handle(req);
        })
      )
      .pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            // TODO: Handle Authentication and Authorization here
          }
          return event;
        })
      );
  }

  protected get authService(): AuthService {
    return this.injector.get(AuthService);
  }
}
