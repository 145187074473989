<div *ngIf="model?.menu" ngbDropdown class="d-inline-block">
  <button
    [disabled]="disableButton"
    ngbDropdownToggle
    id="dropdownBasic1"
    class="btn rounded-0"
    [ngStyle]="props.buttonStyle"
    [ngClass]="{
      'dropdown-toggle': showCarret,
      white: whiteDropdownArrow && props.buttonLabel && showCarret,
      black: !whiteDropdownArrow && props.buttonLabel && showCarret,
      'white-no-label': whiteDropdownArrow && !props.buttonLabel && showCarret,
      'black-no-label': !whiteDropdownArrow && !props.buttonLabel && showCarret,
      'pr-0': badgeValue && disableButton
    }"
    [style.background-color]="props.buttonColor"
  >
    <ds365-icon *ngIf="props.buttonImg" [svgSize]="props.buttonIconSize" [svgStyle]="props.buttonIconFill" [svgName]="props.buttonImg" status="gray"></ds365-icon>
    <span *ngIf="badgeValue" class="fs-12" [ngClass]="[labelClass ? labelClass : 'text-white']" [ngStyle]="labelStyle"
      >{{ badgeValue }}
    </span>
    <br *ngIf="props.buttonLabel" />
    <span
      *ngIf="props.buttonLabel"
      class="fs-12"
      [ngClass]="[
        !showCarret ? 'text-nowrap ml-n1' : '',
        showCarret ? 'ml-n2' : '',
        labelClass ? labelClass : 'text-white'
      ]"
      [ngStyle]="labelStyle"
      >{{ props.buttonLabel }}
    </span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [ngStyle]="dropdownStyle">
    <ng-container *ngFor="let item of model.menu">
      <button
        ngbDropdownItem
        class="dd-button"
        *ngIf="!item?.hidden && props.buttonLabel != item?.labelWhenSelected"
        (click)="itemClick($event, item)"
        [ngbTooltip]="item.tooltip ? item.tooltip : ''"
        placement="left"
      >
        <ds365-icon
          *ngIf="item.icon"
          [svgSize]="item.iconSize"
          [svgName]="item.icon"
          [svgStyle]="item.iconFill"
          status="gray"
        ></ds365-icon>
        <span [ngClass]="{ 'ml-2': item.icon }" class="align-middle fs-12">{{ item.label }}</span>
      </button>
    </ng-container>
  </div>
</div>

<div *ngIf="!model?.menu" class="d-inline-block">
  <button
    class="btn rounded-0  cursor-default"
    [disabled]="disableButton"
    [ngStyle]="props.buttonStyle"
    [style.background-color]="props.buttonColor"
  >
    <ds365-icon *ngIf="props.buttonImg" [svgSize]="props.buttonIconSize" [svgStyle]="buttonIconFill" [svgName]="props.buttonImg" status="gray"></ds365-icon>
    <span *ngIf="badgeValue" class="fs-12" [ngClass]="[labelClass ? labelClass : 'text-white']" [ngStyle]="labelStyle"
      >{{ badgeValue }}
    </span>
    <br *ngIf="props.buttonLabel" />
    <span class="fs-12 ml-n1" [ngClass]="[labelClass ? labelClass : 'text-white']">{{ props.buttonLabel }} </span>
  </button>
</div>
