import { Component } from '@angular/core';
import { BANNER_CONTENT } from '../banner.token';
import { BannerContent } from '../banner.model';

@Component({
  selector: 'ds365-banner-wizard-buttons',
  template: `
    <div class="container">
      <div class="row">
        <div class="col-sm-6 offset-sm-3  align-self-center">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  providers: [
    { provide: BANNER_CONTENT, useExisting: BannerWizardButtonsComponent },
  ],
})
export class BannerWizardButtonsComponent implements BannerContent {
  public key: string;
}
