<div class="card rounded-0 border text-white" [style.width]="width">
  <div class="card-header rounded-0" *ngIf="!videoOnly">
    <div class="float-left">
      <a class="link-unstyled">
        <span class="card-title font-weight-bold" [ngbTooltip]="video?.title" placement="bottom">{{
          video?.title | truncate: ['40', '...']
        }}</span>
      </a>
      <div>
        <span class="fs-12 text-muted">{{ video?.author }} | {{ video?.dateCreated }} | {{ video?.length }}</span>
      </div>
    </div>
  </div>
  <div class="card-body p-0">
    <div
      id="projectImage"
      class="card-img rounded-0 d-flex justify-content-center align-items-center"
     [style.background-image]="videoImage"
    > 
      <ds365-icon svgName="player" svgSize="64" class="cursor-pointer" (click)="playVideo.emit()"></ds365-icon>
    </div>
  </div>
  <div class="card-footer rounded-0 fs-12 " *ngIf="!videoOnly">
    <p class="card-text font-weight-light" [ngbTooltip]="video?.description">
      {{ video?.description | truncate: ['150', '...'] }}
    </p>
  </div>
  <div class="card-tag bg-dark pl-4   py-3 mt-2 rounded-0 fs-12 " *ngIf="!videoOnly">
    <b>Tags: </b>
    <span   ngbTooltip="{{ video?.tags.join(', ') }}">{{ video?.tags.join(', ') | truncate: ['54', '...'] }}</span>
  </div>
</div>
