<ul class="list-unstyled steps-indicator">
  <li *ngFor="let step of steps; let i = index;">
    <a>
      <div class="step-indicator">
        <ng-container *ngIf="step.stepIndicatorTemplate" [ngTemplateOutlet]="step.stepIndicatorTemplate.templateRef"
          [ngTemplateOutletContext]="{step: step}">
        </ng-container>
      </div>
    </a>
  </li>
</ul>
