import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbTypeaheadModule, NgbDatepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {
  ButtonDirective,
  CheckboxComponent,
  InputComponent,
  RadioGroupComponent,
  SelectComponent,
} from '@theme/controls';
import { PaginatorComponent } from './controls/paginator/paginator.component';
import { IsInvalidDirective, PlaceholderDirective } from './directives';
import { FooterModule } from './layouts';
import { BadgeModule } from './layouts/badge/badge.module';
import { BannerModule } from './layouts/banner/banner.module';
import { ChartModule } from './layouts/chart/chart.module';
import { ConfirmBoxComponent, ConfirmBoxService, CustomConfirmBoxComponent } from './layouts/confirm-box';
import { IconModule } from './layouts/icon/icon.module';
import { SidebarModule } from './layouts/sidebar/sidebar.module';
import { LoaderComponent, LoaderDirective } from './layouts/loader';
import { IconTileComponent } from './layouts/icon-tile/icon-tile.component';
import { TextAreaComponent } from './controls/text-area/text-area.component';
import { DatePickerComponent } from './controls/date-picker/date-picker.component';
import { MultiSelectComponent } from './controls/multi-select/multi-select.component';
import { StepperComponent } from './controls/stepper/stepper.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StepComponent } from './controls/stepper/step/step.component';
import { StepperNextDirective } from './controls/stepper/stepper-next.directive';
import { StepperPreviousDirective } from './controls/stepper/stepper-previous.directive';
import { StepperNavigationBarComponent } from './controls/stepper/stepper-navigation-bar/stepper-navigation-bar.component';
import { StepIndicatorDirective } from './controls/stepper/stepper-navigation-bar/step-indicator.directive';
import { PopupComponent } from './layouts/popup/popup.component';
import { CustomAlertBoxComponent } from './layouts/confirm-box/custom-alert-box.component';
import { TypeaheadComponent } from './controls/typeahead/typeahead.component';
import { VideoPlayerComponent } from './layouts/video-player/video-player.component';
import { DpDatePickerModule, ɵb as UtilsService, ɵf as DomHelper } from 'ng2-date-picker';
import { VideoTileComponent } from '@theme/layouts/video-tile/video-tile.component';
import { CoreModule } from '@core/core.module';
import { GetStartedComponent } from '@theme/get-started/get-started.component';
import { HeaderModule } from './layouts/header/header.module';
import { NgxDateRangeModule } from 'ngx-daterange';
import { DateRangePickerComponent } from './controls/date-range-picker/date-range-picker.component';


const MODULES = [
  CoreModule,
  HeaderModule,
  BannerModule,
  ChartModule,
  FooterModule,
  IconModule,
  BadgeModule,
  NgbDropdownModule,
  NgbTypeaheadModule,
  NgbDatepickerModule,
  NgbTooltipModule,
  SidebarModule,
  CdkStepperModule,
  NgxDateRangeModule
];
const COMPONENTS = [
  InputComponent,
  RadioGroupComponent,
  CheckboxComponent,
  SelectComponent,
  ButtonDirective,
  LoaderComponent,
  LoaderDirective,
  ConfirmBoxComponent,
  CustomConfirmBoxComponent,
  CustomAlertBoxComponent,
  PlaceholderDirective,
  IsInvalidDirective,
  PaginatorComponent,
  IconTileComponent,
  TextAreaComponent,
  DatePickerComponent,
  MultiSelectComponent,
  StepperComponent,
  StepComponent,
  StepperNextDirective,
  StepperPreviousDirective,
  StepperNavigationBarComponent,
  StepIndicatorDirective,
  PopupComponent,
  TypeaheadComponent,
  VideoPlayerComponent,
  VideoTileComponent,
  DateRangePickerComponent
];

@NgModule({
  declarations: [
    ...COMPONENTS,
    IconTileComponent,
    TextAreaComponent,
    DatePickerComponent,
    MultiSelectComponent,
    StepperComponent,
    StepComponent,
    StepperNavigationBarComponent,
    PopupComponent,
    GetStartedComponent
  ],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, ...MODULES, DpDatePickerModule],
  exports: [...COMPONENTS, ...MODULES],
  entryComponents: [LoaderComponent, ConfirmBoxComponent, CustomConfirmBoxComponent,
    CustomAlertBoxComponent, VideoPlayerComponent, PopupComponent, GetStartedComponent],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [ConfirmBoxService, UtilsService, DomHelper ],
    } as ModuleWithProviders<ThemeModule>;
  }
}
